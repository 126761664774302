import { MenuItem, Select, Typography } from "@mui/material";
import React from "react";

/**
 * Select listas su vienu pasirinkimu
 * @param {string} pavadinimas tekstas virs select listo
 * @param {[]} pasirinkimai visu galimu pasirinkimu masyvas
 * @param {int} reiksme pasirinkto varianto id
 * @param {() => {}} changeGrupes set funckija, kuri pakeicia busena
 * @returns
 */
const SingleSelect = ({ pavadinimas, pasirinkimai, reiksme, changeReiksme }) => {
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Select
        id={pavadinimas}
        name={pavadinimas}
        value={reiksme}
        size="small"
        onChange={(e) => changeReiksme(e.target.value)}
        sx={{ minWidth: "100px" }}
        fullWidth
      >
        {pasirinkimai.map((p) => (
          <MenuItem key={p.id} value={p.id}>
            {p.label}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default SingleSelect;
