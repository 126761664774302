export const Spalvos = [
  '#2f4f4f',
  '#228b22',
  '#800000',
  '#00008b',
  '#ff8c00',
  '#2a5df7',
  '#00ff00',
  '#00ffff',
  '#ff00ff',
  '#eee8aa',
  '#6495ed',
  '#ff69b4'
];

export const PapildomosSpalvos = [
  '#7030a0', //10
  '#00b050', //6
  '#0070c0', //8
  '#ff0000', //2
  "#808000", //Olive
  '#ffc000', //3
  '#002060', //9
  '#ffff00', //4
  '#c00000', //1
  '#92d050', //5
  '#00b0f0', //7
];

export const BandosKilmesSpalvos = [
  "#800000",
  "#808000",
  "#FF0000",
  "#000080",
  "#0000FF",
  "#008080"
]

export const X11colors = [
  '#800000', //Maroon
  "#A52A2A", //Brown
  "#808000", //Olive
  "#FF0000", //Red
  "#FFA500", //Orange
  "#FFFF00", //Yellow
  "#CCFF00", //Lime
  "#008000", //Green
  "#FFC0CB", //Pink
  "#FF7F50", //Coral
  "#F5F5DC", //Beige
  "#98FB98", //Mint
  "#008080", //Teal
  "#000080", //Navy
  "#00FFFF", //Cyan
  "#0000FF", //Blue
  "#800080", //Purple
  "#FF00FF", //Magenta
  "#E6E6FA", //Lavender
  "#000000", //Black
  "#808080", //Grey
  "#FFFFFF", //White
]

export const Primary = '#1e56c2';
