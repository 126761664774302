import { Paper, Typography } from "@mui/material";
import styled from "@emotion/styled";

export const StyledPaper = styled(Paper)`
  padding: 16px;
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  align-items: ${(props) => (props.alignitems ? props.alignitems : 'flex-start')};
  overflow: visible;
`;

export const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  border: 1px solid;
  padding: 8px;
  z-index: 1;
  background-color: white;
  overflow: visible;
`;

export const StyledLabel = styled(Typography)`
  padding: 0px;
  margin: 0px;
  font-weight: 400;
  color: ${(props) => (props.color ? props.color : 'black')};
`;
