import { useEffect, useState, useRef } from "react";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { StyledPaper } from "../component/shared/StyledComponents";
import StrukturosPasirinkimai from "../component/BandosStruktura/StrukturosPasirinkimai";
import BandosKitimoGrafikas from "../component/kitimoIstorija/KitimoLinija";
import { useAuth } from "../context/AuthProvider";
import { useMenu } from "../context/MenuProvider";
import { getItem } from "../Api/Api";
import PasiskirstymasPagalKilmeStulpeline from "../component/kitimoIstorija/PasiskirstymasPagalKilmeStulpeline";
import { PapildomosSpalvos } from "../constants/Spalvos";
import Slankjuoste from "../component/kitimoIstorija/Slankjuoste";
import { SvieziapieniuMarks, SvieziapieniuProcentuMarks } from "../constants/SlankjuostesZingniai";
import { urlSuParametrais } from "../functions/urlSuParametrais";
import {
  mappintiSarasoDuomenis,
  mappintiDuomenis,
  mappintiDuomenisSuDaugStulpeliu,
} from "../functions/duomenuMappinimas";

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

const BandosKitimas = () => {
  const auth = useAuth();
  const menu = useMenu();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVeislems, setIsLoadingVeislems] = useState(isLoading);
  const [bandos, setBandos] = useState(0);
  const [veisles, setVeisles] = useState(0);
  const [duomenys, setDuomenys] = useState([]);
  const [duomenysVeislems, setDuomenysVeislems] = useState(duomenys);
  const [svieziapieniuDienos, setSvieziapieniuDienos] = useState(45);
  const [svieziapieniuProcentas, setSvieziapieniuProcentas] = useState(30);
  const [svieziapienes, setSvieziapienes] = useState([]);
  const [isLoadingSvieziapienes, setIsLoadingSvieziapienes] = useState(false);

  const arSavininkas = auth.user.role === "Ūkis";

  // Senos busenos
  const senosBandos = usePrevious(bandos);
  const senosVeisles = usePrevious(veisles);
  const previousSavininkas = usePrevious(menu.savininkas);

  useEffect(() => {
    // patikrina, ar nepasikeite seimininkas,
    const shouldFetch = menu.savininkas !== "" || arSavininkas;
    if (!shouldFetch) return;

    // kas pasikeite nuo paskutinio refresh
    const bandosChanged = senosBandos !== bandos && senosBandos !== undefined;
    const veislesChanged = senosVeisles !== veisles && senosVeisles !== undefined;
    const savininkasChanged = previousSavininkas !== menu.savininkas;
    const isInitialLoad = senosBandos === undefined;

    if (bandosChanged || isInitialLoad || savininkasChanged) {
      setIsLoading(true);
      setIsLoadingVeislems(true);
    } else if (veislesChanged) {
      setIsLoadingVeislems(true);
    }
    const params = [
      { parameter: arSavininkas, condition: "false", append: "savininkas", value: menu.savininkas },
      { parameter: bandos, condition: "!==0", append: "banda", value: bandos },
      { parameter: veisles, condition: "!==0", append: "veisle", value: veisles },
    ];

    getItem(urlSuParametrais("savininkai/banda/struktura/kitimas", params))
      .then((data) => {
        if (data.data) {
          if (bandosChanged || isInitialLoad || savininkasChanged) {
            setDuomenys(data.data);
            setDuomenysVeislems(data.data);
            setIsLoading(false);
            setIsLoadingVeislems(false);
          } else if (veislesChanged) {
            setDuomenysVeislems(data.data);
            setIsLoadingVeislems(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setIsLoadingVeislems(false);
        setDuomenys([]);
        setDuomenysVeislems([]);
        if (err.response?.status === 401) {
          auth.logout();
        }
      });
  }, [menu.savininkas, arSavininkas, auth, bandos, veisles]);

  useEffect(() => {
    const params = [
      { parameter: arSavininkas, condition: "false", append: "savininkas", value: menu.savininkas },
      { parameter: bandos, condition: "!==0", append: "banda", value: bandos },
      {
        parameter: svieziapieniuDienos,
        condition: "!==0",
        append: "dienos",
        value: svieziapieniuDienos,
      },
    ];

    setIsLoadingSvieziapienes(true);
    getItem(urlSuParametrais("svieziapienes", params))
      .then((data) => {
        data.data && setSvieziapienes(data.data);
        setIsLoadingSvieziapienes(false);
      })
      .catch((err) => {
        setIsLoadingSvieziapienes(false);
        setSvieziapienes([]);
        if (err.response.status === 401) {
          auth.logout();
        }
      });
  }, [menu.savininkas, bandos, svieziapieniuDienos]);

  const svieziepieniuMenesiai = svieziapienes.map(({ menesis }) => ({
    Mėnesis: menesis,
  }));

  const sviezios = mappintiDuomenisSuDaugStulpeliu(
    svieziapienes,
    "svieziapieniu",
    ["svieziapieniu", "svieziapieniuProc"],
    ["Šviežiapienių", "Šviežiapienių %"],
    svieziepieniuMenesiai
  );

  const [minBarHeight, setMinBarHeight] = useState(200);

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.innerWidth < 800) {
        setMinBarHeight(400);
      } else {
        setMinBarHeight(200);
      }
    };

    updateLegendPosition(); // nustato pradine puslapio busena
    window.addEventListener("resize", updateLegendPosition);

    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  useEffect(() => {
    setBandos(0);
    setVeisles(0);
  }, [menu.savininkas]);

  const menesiaiDalys =
    duomenys &&
    duomenys
      .filter(({ karviu, telyciu }) => karviu > 0 || telyciu > 0)
      .map(({ menuo }) => ({ Mėnesis: menuo }));

  const karves = mappintiDuomenisSuDaugStulpeliu(
    duomenys,
    "karviu",
    ["karviu", "karviuProc"],
    ["Karvių", "Karvių %"],
    menesiaiDalys
  );
  const telyciu = mappintiDuomenisSuDaugStulpeliu(
    duomenys,
    "telyciu",
    ["telyciu", "telycProc"],
    ["Telyčių", "Telyčių %"],
    menesiaiDalys
  );

  const karviuIrTelyciuDalys = menesiaiDalys.map((item, index) => ({
    ...item,
    ...karves[index],
    ...telyciu[index],
  }));

  const ekonomiskaTelyciuDalis = karviuIrTelyciuDalys.map((item) => ({
    "Vidutinis ekonomiškas telyčių skaičius bandoje (35%)": Number(
      ((item.Karvių + item.Telyčių) * 0.35).toFixed()
    ),
    Mėnesis: item.Mėnesis,
  }));

  const karviuKilmes = mappintiDuomenisSuDaugStulpeliu(
    duomenysVeislems,
    "karviu",
    ["karviuTuscia", "karviuPagr", "karviuPap"],
    ["Karvių Tuščia", "Karvių Pagrindinė", "Karvių Papildoma"],
    menesiaiDalys
  );

  const telyciuKilmes = mappintiDuomenisSuDaugStulpeliu(
    duomenysVeislems,
    "telyciu",
    ["telyciuTuscia", "telyciuPagr", "telyciuPap"],
    ["Telyčių Tuščia", "Telyčių Pagrindinė", "Telyčių Papildoma"],
    menesiaiDalys
  );

  const stacks = ["Karvių", "Telyčių"];

  const kilmiuDalys = menesiaiDalys.map((item, index) => ({
    ...item,
    ...karviuKilmes[index],
    ...telyciuKilmes[index],
  }));

  const laktacijuDalys = mappintiSarasoDuomenis(duomenys, "laktacijos", menesiaiDalys);
  const telyciuAmziausDalys = mappintiSarasoDuomenis(duomenys, "telyciuAmziai", menesiaiDalys);

  const laktAmziuDalys = mappintiDuomenis(
    duomenys,
    "laktAmzius",
    "Laktacijos amžius",
    menesiaiDalys
  );
  const laktTrukmeDalys = mappintiDuomenis(
    duomenys,
    "laktTrukme",
    "Laktacijos trukmė",
    menesiaiDalys
  );
  const kritinisDienuSkaicius = 180;
  const kritinisDienuSkaiciusLegenda =
    "Kritinis dienų skaičius (180 d.) gerai reprodukcijai, savalaikio ir tikslingo gyvulių brokavimo ir bandos atsinaujinimo atžvilgiu";

  const pirmaversiuDalys = mappintiDuomenis(duomenys, "pirmaversiu", "Pirmaveršių", menesiaiDalys);

  const kontrolineSvieziapieniuDalis = duomenys.map((item) => ({
    [`Ekonomiškas bandos išnaudojimas atsinaujinimo atžvilgiu (${svieziapieniuProcentas}%)`]:
      Number(
        (item.karviu * (svieziapieniuProcentas / 100)).toFixed() // vartotojas 03909255 turi tik 100, kai reiktu jam tureti apie 1k
      ),
    Mėnesis: item.menuo,
  }));

  const uztrukusiuDalys = mappintiDuomenisSuDaugStulpeliu(
    duomenys,
    "uztrukusiu",
    ["uztrukusiu", "uztrukusiuProc"],
    ["Užtrūkusių", "Užtrūkusių %"],
    menesiaiDalys
  );

  const uztrukusiuKontrolineDalis = duomenys.map((item) => ({
    "Standartinė užtrūkusių karvių dalis bandoje 15%": Number((item.karviu * 0.15).toFixed()),
    Mėnesis: item.menuo,
  }));

  return (
    <>
      <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Bandos kitimas
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <StyledPaper
              sx={{
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column", // Stack items vertically
              }}
            >
              <StrukturosPasirinkimai
                banda={bandos}
                setBanda={setBandos}
                veisle={veisles}
                setVeisle={setVeisles}
                visosBandos={false}
              />
              <Slankjuoste
                min={45}
                max={90}
                numatytoji={svieziapieniuDienos}
                zingsnis={5}
                label="Dienos po apsiveršiavimo"
                marks={SvieziapieniuMarks}
                changeValue={setSvieziapieniuDienos}
              />
              <Slankjuoste
                min={20}
                max={40}
                numatytoji={svieziapieniuProcentas}
                zingsnis={2}
                label="Šviežiapienių dalis %"
                marks={SvieziapieniuProcentuMarks}
                changeValue={setSvieziapieniuProcentas}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6} lg={9}>
            <StyledPaper
              sx={{
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                minHeight: { minBarHeight },
              }}
            >
              <PasiskirstymasPagalKilmeStulpeline
                isLoading={isLoadingVeislems}
                pavadinimas="Karvių ir telyčių pasiskirstymas pagal kilmės knygos skyrius(%)"
                data={kilmiuDalys}
                multi={true}
                xAxis="Mėnesis"
                stacks={stacks}
                spalvos={PapildomosSpalvos}
                barHeight={400}
              />
            </StyledPaper>
          </Grid>
          <Grid item container lg={12} spacing={2}>
            <Grid item lg={6} xs={12}>
              <StyledPaper
                sx={{
                  gap: "30px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: { minBarHeight },
                }}
              >
                <PasiskirstymasPagalKilmeStulpeline
                  key={"Telyčių skaičius pagal amžių mėnesiais %"}
                  isLoading={isLoading}
                  pavadinimas="Telyčių skaičius pagal amžių mėnesiais %"
                  data={telyciuAmziausDalys}
                  xAxis="Mėnesis"
                  spalvos={PapildomosSpalvos}
                  barHeight={250}
                />
              </StyledPaper>
            </Grid>
            <Grid item lg={6} xs={12}>
              <StyledPaper
                sx={{
                  gap: "30px",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: { minBarHeight },
                }}
              >
                <PasiskirstymasPagalKilmeStulpeline
                  key={"Karvių skaičius pagal amžių laktacijomis(%)"}
                  isLoading={isLoading}
                  pavadinimas="Karvių skaičius pagal amžių laktacijomis(%)"
                  data={laktacijuDalys}
                  xAxis="Mėnesis"
                  multi={false}
                  spalvos={PapildomosSpalvos}
                  barHeight={250}
                />
              </StyledPaper>
            </Grid>
            <Grid item container lg={12} spacing={2}>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    key={"Karvių ir telyčių skaičių ir procentų kitimas"}
                    duomenys={karviuIrTelyciuDalys}
                    pavadinimas="Karvių ir telyčių skaičių ir procentų kitimas"
                    isLoading={isLoading}
                    xAxis="Mėnesis"
                    customTooltip={true}
                    stacks={stacks}
                    kontroliniaiDuomenys={ekonomiskaTelyciuDalis}
                    pavadinimasLegendoje="Vidutinis ekonomiškas telyčių skaičius bandoje (35%)"
                    pavadinimasGrafike="Ekonomiškas skaičius* (35%)"
                  />
                </StyledPaper>
              </Grid>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    duomenys={laktAmziuDalys}
                    pavadinimas="Vidutinis karvių amžius laktacijomis"
                    isLoading={isLoading}
                    xAxis="Mėnesis"
                    etikete
                    papildomasResponsive
                  />
                </StyledPaper>
              </Grid>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    duomenys={laktTrukmeDalys}
                    pavadinimas="Vidutinė laktacijos trukmė dienomis"
                    isLoading={isLoading}
                    xAxis="Mėnesis"
                    // unit="d."
                    kontrolineLinija={kritinisDienuSkaicius}
                    kontrolineLegenda={kritinisDienuSkaiciusLegenda}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
            <Grid item container lg={12} spacing={2}>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    duomenys={pirmaversiuDalys}
                    pavadinimas="Pirmaveršių karvių dalis bandoje"
                    isLoading={isLoading}
                    xAxis={"Mėnesis"}
                    unit={"%"}
                  />
                </StyledPaper>
              </Grid>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    // duomenys={svieziuDalys}
                    duomenys={sviezios}
                    pavadinimas={`Šviežiapienių karvių dalis bandoje (${svieziapieniuDienos} d. po apsiveršiavimo)`}
                    // isLoading={isLoading}
                    isLoading={isLoadingSvieziapienes}
                    xAxis="Mėnesis"
                    customTooltip={true}
                    // tooltip={2}
                    kontroliniaiDuomenys={kontrolineSvieziapieniuDalis}
                    pavadinimasLegendoje={`Ekonomiškas bandos išnaudojimas atsinaujinimo atžvilgiu (${svieziapieniuProcentas}%)`}
                    pavadinimasGrafike={`Ekonomiškas išnaudojimas* (${svieziapieniuProcentas}%)`}
                    etikete
                  />
                </StyledPaper>
              </Grid>
              <Grid item lg={4} xs={12}>
                <StyledPaper
                  sx={{
                    gap: "30px",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    minHeight: { minBarHeight },
                  }}
                >
                  <BandosKitimoGrafikas
                    duomenys={uztrukusiuDalys}
                    pavadinimas="Užtrūkusių karvių skaičiaus dalis bandoje"
                    isLoading={isLoading}
                    xAxis="Mėnesis"
                    customTooltip={true}
                    kontroliniaiDuomenys={uztrukusiuKontrolineDalis}
                    pavadinimasLegendoje="Standartinė užtrūkusių karvių dalis bandoje 15%"
                    pavadinimasGrafike="Standartinė dalis* (15%)"
                    etikete
                  />
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default BandosKitimas;
