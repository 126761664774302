import { Typography } from "@mui/material";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { Primary } from "../../constants/Spalvos";
import { useEffect, useState } from "react";
import Ranges from "../../constants/DiagramosDiapozonai";
import DuomenysKraunami from "../shared/DuomenysKraunami";
import DuomenuNera from "../shared/DuomenuNera";

const BarColors = [Primary, "#8e8989", "#3d3e40", "#060606", "#1068f0"];
const OtherColors = [Primary, "#8e8989", "#3d3e40", "#061126", "#153c87", "#6188d4"];

function findGlobalMax(array, raktas, groups) {
  // patikrina ar yra duomenu
  if (!Array.isArray(array) || array.length === 0 || !array[0]) {
    return 0;
  }

  // patikrina ar yra skaitiniu raktu
  const hasNumericKeys = Object.keys(array[0]).some((key) => {
    if (key === raktas) return false;

    return (
      !isNaN(key) || // pure numbers
      key.includes("-") || // ranges
      key.startsWith("<") || // less than
      key.startsWith(">") || // greater than
      key.includes("+") // plus format
    );
  });
  let globalMax = -Infinity;

  array.forEach((obj) => {
    if (hasNumericKeys) {
      // skaitiniu raktu suma
      let sum = 0;
      for (const key in obj) {
        if (key !== raktas) {
          const value = parseFloat(obj[key] || 0);
          if (!isNaN(value)) {
            sum += value;
          }
        }
      }
      globalMax = Math.max(globalMax, sum);
    } else {
      // grupems
      if (Array.isArray(groups)) {
        // grupiu masyvas
        const groupSums = {};
        groups.forEach((group) => {
          groupSums[group] = 0;
        });
        // kiekvienos grupes suma
        for (const key in obj) {
          const value = parseFloat(obj[key] || 0);
          if (!isNaN(value)) {
            // prideda suma grupei kuriai reikia
            for (const group of groups) {
              if (key.startsWith(group)) {
                groupSums[group] += value;
                break;
              }
            }
          }
        }
        // grazina globalu max
        globalMax = Math.max(globalMax, ...Object.values(groupSums));
      }
    }
  });

  return globalMax === -Infinity ? 0 : globalMax;
}

const gautiDiapozonaIrSkyrius = (globalMax) => {
  let ticks = [0, 25, 50, 75, 100];
  let domain = [0];

  const range = Ranges.find((r) => globalMax <= r.max);

  if (range) {
    ticks = range.ticks;
    domain.push(range.domain);
  } else {
    domain.push(100);
    ticks = [0, 25, 50, 75, 100];
  }

  return [ticks, domain];
};

const PasiskirstymasPagalKilmeStulpeline = ({
  isLoading,
  pavadinimas,
  data,
  xAxis,
  stacks = null,
  spalvos = null,
  barHeight = 400,
}) => {
  const [ticks, domain] = gautiDiapozonaIrSkyrius(findGlobalMax(data, xAxis, stacks));

  const [legendProps, setLegendProps] = useState({
    align: "right",
    verticalAlign: "middle",
  });
  const [minBarHeight, setMinBarHeight] = useState(200);

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.innerWidth < 800) {
        setLegendProps({ align: "center", verticalAlign: "bottom", layout: "horizontal" });
        setMinBarHeight(barHeight ? barHeight : 400);
      } else {
        setLegendProps({ align: "right", verticalAlign: "middle", layout: "vertical" });
        setMinBarHeight(200);
      }
    };

    updateLegendPosition(); // Set initial state
    window.addEventListener("resize", updateLegendPosition);

    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  if (isLoading) {
    return <DuomenysKraunami pavadinimas={pavadinimas} />;
  }

  if (!data || data.length === 0 || data === null) {
    return <DuomenuNera pavadinimas={pavadinimas} />;
  }

  const raktai = [...new Set(data.flatMap(Object.keys))].filter(
    (x) => x !== "Mėnesis" && x !== "stackId"
  );

  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <ResponsiveContainer width="100%" height="100%" minHeight={minBarHeight}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            // right: 30,
            // left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="2 2" />
          <XAxis dataKey={xAxis} />
          <YAxis domain={domain} ticks={ticks} unit="%" />
          <Tooltip />
          <Legend layout="vertical" {...legendProps} wrapperStyle={{ paddingLeft: "20px" }} />
          {raktai.map((k, index) => (
            <Bar
              key={k}
              dataKey={k}
              stackId={
                stacks && Array.isArray(stacks)
                  ? stacks
                      .map((item, stackIndex) =>
                        // sukuria stak id abeceles tvarka.
                        k.includes(item) ? String.fromCharCode(97 + stackIndex) : null
                      ) // Find the first non-null stackId (i.e., letter)
                      .find((letter) => letter !== null)
                  : "a"
              }
              fill={
                raktai.length <= 3
                  ? BarColors[index]
                  : spalvos
                  ? spalvos[index]
                  : OtherColors[index]
              }
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default PasiskirstymasPagalKilmeStulpeline;
