import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import { StyledPaper } from "../component/shared/StyledComponents";
import { SkaiciuBox } from "./MitybosVertinimas";
import { useAuth } from "../context/AuthProvider";
import { useMenu } from "../context/MenuProvider";
import { kontrolesPeriodai } from "../constants/KontrolesPeriodai";
import { LaktacijuAmziai, LaktacijuTrukmes, SomatiniuGrupes } from "../constants/ParametruGrupes";
import SveikatingumoPasirinkimai from "../component/Sveikatingumas/SveikatingumoPasirinkimai";
import { getItem } from "../Api/Api";
import { filtruotiGrupes } from "./Home";
import SveikatStulpGrafikas from "../component/Sveikatingumas/SveikatStulpGrafikas";
import SveikatSkritDiagrama from "../component/Sveikatingumas/SveikatSkritDiagrama";
import SveikatingumoGrafikas from "../component/Sveikatingumas/SveikatingumoGrafikas";

const Sveikatingumas = () => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === "Ūkis";

  const [isLoading, setIsLoading] = useState(false);
  const [duomenys, setDuomenys] = useState([]);
  const [bandos, setBandos] = useState([]);
  const [menesis, setMenesis] = useState(kontrolesPeriodai[0]);
  const [grupes, setGrupes] = useState(SomatiniuGrupes.map((g) => g.id));
  const [laktacijos, setLaktacijos] = useState(LaktacijuAmziai.map((g) => g.id));
  const [veisles, setVeisles] = useState([]);
  const [laktTrukmes, setLaktTrukmes] = useState(LaktacijuTrukmes.map((t) => t.id));

  useEffect(() => {
    if ((menu.savininkas !== "" || arSavininkas) && menesis != null) {
      setIsLoading(true);
      const url = `savininkai/sls?menuo=${menesis}`.concat(
        arSavininkas ? "" : `&savininkas=${menu.savininkas}`
      );
      getItem(url)
        .then((data) => {
          data.data && setDuomenys(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setDuomenys([]);
          if (err.response.status === 401) {
            auth.logout();
          }
        });
    }
  }, [menu.savininkas, arSavininkas, auth, menesis]);

  const veisliuList = [...new Set(duomenys.map((d) => d.veisle))];
  let filtruota = duomenys;

  bandos.length > 0 && (filtruota = filtruota.filter((d) => bandos.indexOf(d.banda) > -1));

  veisles.length > 0 &&
    veisles.length < veisliuList.length &&
    (filtruota = filtruota.filter((d) => veisles.indexOf(d.veisle) > -1));

  //Filtruojam laktaciju grupes
  //laktacijos.length > 0 &&
  laktacijos.length < LaktacijuAmziai.length &&
    (filtruota = filtruotiGrupes(laktacijos, LaktacijuAmziai, filtruota, "laktAmzius"));

  //Filtruojam laktaciju trukmes
  //laktTrukmes.length > 0 &&
  laktTrukmes.length < LaktacijuTrukmes.length &&
    (filtruota = filtruotiGrupes(laktTrukmes, LaktacijuTrukmes, filtruota, "laktDiena"));

  //Filtruojam somatiniu grupes
  //grupes.length > 0 &&
  grupes.length < SomatiniuGrupes.length &&
    (filtruota = filtruotiGrupes(grupes, SomatiniuGrupes, filtruota, "sls"));

  const veisliuMap = new Map(veisliuList.map((v) => [v, { count: 0, sum: 0 }]));
  const laktAmzKiekiai = [
    { label: "1", count: 0, sum: 0 },
    { label: "2", count: 0, sum: 0 },
    { label: "3", count: 0, sum: 0 },
    { label: "4-5", count: 0, sum: 0 },
    { label: "5+", count: 0, sum: 0 },
  ];
  const laktTrukmeKiekiai = [
    { label: "0-60", count: 0, sum: 0 },
    { label: "61-120", count: 0, sum: 0 },
    { label: "121-200", count: 0, sum: 0 },
    { label: "201-305", count: 0, sum: 0 },
    { label: ">305", count: 0, sum: 0 },
  ];
  const slsGrupiuKiekiai = [
    { name: "0-200", count: 0 },
    { name: "201-400", count: 0 },
    { name: "401-1000", count: 0 },
    { name: ">1000", count: 0 },
  ];
  const bendriSkaiciai = {
    vidSls: 0,
    infektuotu: 0,
    virs200: 0,
  };

  for (let i = 0; i < filtruota.length; i++) {
    let veislObj = veisliuMap.get(filtruota[i].veisle);
    veisliuMap.set(filtruota[i].veisle, {
      count: veislObj.count + 1,
      sum: veislObj.sum + filtruota[i].sls,
    });

    if (filtruota[i].laktAmzius > 5) {
      laktAmzKiekiai[4].count++;
      laktAmzKiekiai[4].sum += filtruota[i].sls;
    } else if (filtruota[i].laktAmzius >= 4) {
      laktAmzKiekiai[3].count++;
      laktAmzKiekiai[3].sum += filtruota[i].sls;
    } else {
      laktAmzKiekiai[filtruota[i].laktAmzius - 1].count++;
      laktAmzKiekiai[filtruota[i].laktAmzius - 1].sum += filtruota[i].sls;
    }

    if (filtruota[i].laktDiena <= 60) {
      laktTrukmeKiekiai[0].count++;
      laktTrukmeKiekiai[0].sum += filtruota[i].sls;
    } else if (filtruota[i].laktDiena <= 120) {
      laktTrukmeKiekiai[1].count++;
      laktTrukmeKiekiai[1].sum += filtruota[i].sls;
    } else if (filtruota[i].laktDiena <= 200) {
      laktTrukmeKiekiai[2].count++;
      laktTrukmeKiekiai[2].sum += filtruota[i].sls;
    } else if (filtruota[i].laktDiena <= 305) {
      laktTrukmeKiekiai[3].count++;
      laktTrukmeKiekiai[3].sum += filtruota[i].sls;
    } else {
      laktTrukmeKiekiai[4].count++;
      laktTrukmeKiekiai[4].sum += filtruota[i].sls;
    }

    slsGrupiuKiekiai[filtruota[i].slsGrupe - 1].count++;
  }

  const veisliuKiekiai = [];
  for (const [key, value] of veisliuMap) {
    veisliuKiekiai.push({
      label: key,
      value: Math.round(value.sum / value.count),
    });
  }

  laktAmzKiekiai.map((l) => (l.value = Math.round(l.sum / l.count)));
  laktTrukmeKiekiai.map((l) => (l.value = Math.round(l.sum / l.count)));

  bendriSkaiciai.vidSls = Math.round(
    laktAmzKiekiai.reduce((sum, item) => sum + item.sum, 0) / filtruota.length
  );
  bendriSkaiciai.infektuotu = slsGrupiuKiekiai[2].count + slsGrupiuKiekiai[3].count;
  bendriSkaiciai.virs200 = bendriSkaiciai.infektuotu + slsGrupiuKiekiai[1].count;

  return (
    <>
      <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Bandos sveikatingumas SLS
        </Typography>
        <Grid container spacing={1}>
          <Grid container item justifyContent="center" spacing={2} sx={{ mb: 2 }}>
            <Grid item xs={12} lg={12}>
              <StyledPaper row="true" sx={{ gap: "30px", justifyContent: "center" }}>
                <SkaiciuBox skaicius={bendriSkaiciai.vidSls} tekstas="Bandos SLS" />
                <SkaiciuBox
                  skaicius={bendriSkaiciai.infektuotu}
                  tekstas="Karvių  sk., kurių piene SLS > 400 tūkst./ml"
                />
                <SkaiciuBox
                  skaicius={bendriSkaiciai.virs200}
                  tekstas="Karvių  sk., kurių piene SLS > 200 tūkst./ml"
                />
              </StyledPaper>
            </Grid>
            <Grid container item justifyContent="center" spacing={2}>
              <Grid item xs={12} lg={2}>
                <StyledPaper sx={{ height: "100%" }}>
                  <SveikatingumoPasirinkimai
                    bandos={bandos}
                    setBandos={setBandos}
                    menesis={menesis}
                    setMenesis={setMenesis}
                    grupes={grupes}
                    setGrupes={setGrupes}
                    laktacijos={laktacijos}
                    setLaktacijos={setLaktacijos}
                    veisliuList={veisliuList}
                    veisles={veisles}
                    setVeisles={setVeisles}
                    laktTrukmes={laktTrukmes}
                    setLaktTrukmes={setLaktTrukmes}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledPaper sx={{ height: "100%" }}>
                  <SveikatStulpGrafikas
                    isLoading={isLoading}
                    pavadinimas="SLS vid. pagal veisles"
                    duomenys={veisliuKiekiai}
                    margin={35}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} lg={3}>
                <StyledPaper sx={{ height: "100%" }}>
                  <SveikatStulpGrafikas
                    isLoading={isLoading}
                    pavadinimas="SLS vid. pagal amžių laktacijomis"
                    duomenys={laktAmzKiekiai}
                    margin={0}
                  />
                  <SveikatStulpGrafikas
                    isLoading={isLoading}
                    pavadinimas="SLS vid. pagal laktacijos stadiją"
                    duomenys={laktTrukmeKiekiai}
                    margin={20}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <StyledPaper sx={{ height: "100%" }}>
                  <SveikatSkritDiagrama
                    isLoading={isLoading}
                    pavadinimas="Karvių sk. pagal SLS"
                    duomenys={slsGrupiuKiekiai}
                    raktas={"count"}
                    startAngle={45}
                    endAngle={405}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} lg={12}>
                <StyledPaper sx={{ height: "100%" }}>
                  <SveikatingumoGrafikas
                    isLoading={isLoading}
                    duomenys={filtruota}
                    laktacijos={laktacijos}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Sveikatingumas;
