import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from './Pages/Login';
import Home from './Pages/Home';
import LaktacjuKreives from "./Pages/LaktacjuKreives";
import UkioLaktKreives from "./Pages/UkioLaktKreives";
import MitybosVertinimas from "./Pages/MitybosVertinimas";
import Sveikatingumas from "./Pages/Sveikatingumas";
import BandosSlsKitimas from "./Pages/BandosSlsKitimas";
import BandosStruktura from "./Pages/BandosStruktura";
import AuthProvider from './context/AuthProvider';
import ProtectedRoute from './component/ProtectedRoute';
import Layout from './component/Layout';
import MenuProvider from './context/MenuProvider';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from './styles/Theme';
import './index.css';
import BandosKitimas from "./Pages/BandosKitimas";


function App() {
  return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="page-container">
          <BrowserRouter>
            <AuthProvider>
              <MenuProvider>
                <Routes>
                  <Route path='/login' element={<Login />} />
                  <Route element={<ProtectedRoute />}>
                    <Route element={<Layout />}>
                      <Route path='/' element={<Home />} />
                      <Route path='/laktacijuKreives' element={<LaktacjuKreives />} />
                      <Route path='/ukioLaktKreives' element={<UkioLaktKreives />} />
                      <Route path='/mitybosVertinimas' element={<MitybosVertinimas />} />
                      <Route path='/sveikatingumas' element={<Sveikatingumas />} />
                      <Route path='/bandosSlsKitimas' element={<BandosSlsKitimas />} />
                      <Route path='/bandosStruktura' element={<BandosStruktura />} />
                      <Route path='/bandosStruktKitimas' element={<BandosKitimas />}/>
                    </Route>
                  </Route>
                  <Route path='*' element={<Navigate to='/login'/>} />
                </Routes>
              </MenuProvider>
            </AuthProvider>
          </BrowserRouter>
        </div>
      </ThemeProvider>
  );
}

export default App;
