import { Box, Typography } from "@mui/material";
import React from "react";
import { LaktacijuAmziai } from "../../constants/ParametruGrupes";
import {
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import { Spalvos } from "../../constants/Spalvos";
import { StyledLabel, StyledTooltip } from "../shared/StyledComponents";
import ExportExcel from "../shared/ExportExcel";

const columns = [
  "Kontr. data",
  "Karvės Nr.",
  "Banda",
  "Veislė",
  "Lakt. amžius",
  "Lakt. diena",
  "SLS",
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`Karvės Nr.: ${payload[1].payload.karNr}`}</StyledLabel>
        <StyledLabel>{`Kontr. data: ${payload[1].payload.kontrData}`}</StyledLabel>
        <StyledLabel>{`Lakt amžius: ${payload[1].payload.laktAmzius}`}</StyledLabel>
        <StyledLabel>{`${payload[0].name}: ${payload[0].value}`}</StyledLabel>
        <StyledLabel>{`Somatinės: ${payload[1].value}`}</StyledLabel>
      </StyledTooltip>
    );
  }

  return null;
};

const SveikatingumoGrafikas = ({ isLoading, duomenys, laktacijos }) => {
  const duomenuArr = [];
  for (let i = 0; i < laktacijos.length; i++) {
    const param = LaktacijuAmziai.find((l) => l.id === laktacijos[i]);
    duomenuArr[i] = {
      laktAmzius: param.label,
      data: duomenys.filter((d) => d.laktAmzius >= param.min && d.laktAmzius <= param.max),
    };
  }

  return (
    <>
      <Typography>SLS pagal laktacijos stadiją</Typography>
      {isLoading ? (
        <Box
          width="100%"
          height={500}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography>Duomenys kraunami...</Typography>
        </Box>
      ) : (
        <>
          <ResponsiveContainer width="100%" height={500}>
            <ScatterChart margin={{ bottom: 16, left: 30 }}>
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="laktDiena"
                name="Laktacijos diena"
                label={{
                  value: "Karvės laktacijos diena kontrolės datai",
                  position: "insideBottom",
                  offset: -10,
                }}
              />
              <YAxis type="number" dataKey="sls" unit="tūkst." />
              <ZAxis range={[80, 81]} />
              <Legend verticalAlign="top" wrapperStyle={{ paddingBottom: "20px" }} />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} content={<CustomTooltip />} />
              {duomenuArr.map((grupe) => (
                <Scatter
                  key={grupe.laktAmzius}
                  name={grupe.laktAmzius}
                  data={grupe.data}
                  fill={Spalvos[LaktacijuAmziai.findIndex((l) => l.label === grupe.laktAmzius)]}
                  opacity={0.6}
                />
              ))}
            </ScatterChart>
          </ResponsiveContainer>
          <ExportExcel
            data={duomenys.map(
              ({ kontrData, karNr, banda, veisle, laktAmzius, laktDiena, sls }) => ({
                kontrData,
                karNr,
                banda,
                veisle,
                laktAmzius,
                laktDiena,
                sls,
              })
            )}
            columns={columns}
            widths={[10, 15, 12, 15, 10, 8]}
            fileName="Sls_duomenys"
          />
        </>
      )}
    </>
  );
};

export default SveikatingumoGrafikas;
