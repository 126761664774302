import { Box, Typography } from "@mui/material"

const DuomenuNera = ({ pavadinimas }) => {
  return (
    <Box
      width='100%'
      height='100%'
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography>
        Negalima paskaičiuoti {<b>"{pavadinimas}"</b>} grafiko, nes trūksta arba nėra duomenų
      </Typography>
    </Box>
  )
}

export default DuomenuNera