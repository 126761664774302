import { Box, IconButton, Typography } from "@mui/material";
import { exportToExcelWithRules } from "../shared/ExportExcel";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthProvider";
import { useMenu } from "../../context/MenuProvider";
import { getItem } from "../../Api/Api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Primary } from "../../constants/Spalvos";

function findLongestValueLengths(arr) {
  let longestLengths = {};

  arr.forEach((obj) => {
    for (let key in obj) {
      if (obj[key] !== null || obj[key] !== undefined) {
        let length = String(obj[key]).length;
        if (!longestLengths[key] || length > longestLengths[key]) {
          longestLengths[key] = length;
        }
      }
    }
  });

  return Object.values(longestLengths);
}

const BandosStrukturaPagalKarvesExport = ({ menesis, onClick, bandos, arSavininkas }) => {
  const auth = useAuth();
  const menu = useMenu();

  const [isLoading, setIsLoading] = useState(false);
  const [noData, setNoData] = useState(false);

  const fetchData = () => {
    if ((menu.savininkas !== "" || arSavininkas) && menesis != null) {
      setIsLoading(true);

      const url = `savininkai/banda/struktura/karves?menuo=${menesis}`
        .concat(bandos === 0 ? "" : `&banda=${bandos}`)
        .concat(arSavininkas ? "" : `&savininkas=${menu.savininkas}`);

      getItem(url)
        .then((data) => {
          if (data.data) {
            const dataToExport = data.data.map((item) => ({
              "Kontrolės data": item.kontrData,
              Lytis: item.lytis,
              Numeris: item.numeris,
              Banda: item.banda,
              Veislė: item.veisle,
              "Laktacijos amžius": item.laktAmzius,
              "KK skyrius":
                item.kkSkyrius === null || item.kkSkyrius === undefined ? "Tuščia" : item.kkSkyrius,
              "Amžius mėnesiais": item.amziusMen,
            }));

            const emptyLaktAmzius = dataToExport.every(
              (item) => item["Laktacijos amžius"] === null
            );
            emptyLaktAmzius &&
              dataToExport.forEach(function (v) {
                delete v["Laktacijos amžius"];
              });

            const emptyAgeMonths = dataToExport.every((item) => item["Amžius mėnesiais"] === null);
            emptyAgeMonths &&
              dataToExport.forEach(function (v) {
                delete v["Amžius mėnesiais"];
              });

            const headerWidths = [...new Set(dataToExport.flatMap(Object.keys))].map(
              (key) => key.length
            );
            const valueWidths = findLongestValueLengths(dataToExport);
            const newWidths = headerWidths.map(
              (_, index) => Math.max(valueWidths[index], headerWidths[index]) + 1
            );

            const title = `${bandos === 0 ? "Visų bandų" : `Bandos`} ${
              bandos === 0 ? "" : bandos
            } struktūra ${menesis}`;

            exportToExcelWithRules({
              data: dataToExport,
              columns: [],
              widths: newWidths,
              fileName: title,
              formatRules: [
                {
                  column: "Amžius mėnesiais",
                  controlColumn: "Lytis",
                  condition: (value, controlValue) => value === null && controlValue === "Karvė",
                  transformValue: () => "",
                },
                {
                  column: "Laktacijos amžius",
                  controlColumn: "Lytis",
                  condition: (value, controlValue) => value === null && controlValue === "Telyčia",
                  transformValue: () => "",
                },
              ],
            });
          }
          setNoData(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setNoData(true);
          setIsLoading(false);
          if (err.response?.status === 401) {
            auth.logout();
          }
        });
    }
  };

  useEffect(() => {
    if (onClick) {
      fetchData();
    }
  }, [onClick]);

  const [topPadding, setTopPadding] = useState(1);
  const [topMargin, setTopMargin] = useState(0);

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.innerWidth < 800) {
        setTopPadding(0);
        setTopMargin(-2);
      } else {
        setTopPadding(1);
        setTopMargin(0);
      }
    };

    updateLegendPosition();
    window.addEventListener("resize", updateLegendPosition);

    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      paddingBottom={1}
      paddingTop={topPadding}
      marginTop={topMargin}
    >
      <IconButton disabled={isLoading} sx={{ color: Primary, padding: 0 }}>
        <Typography>
          {isLoading ? "Kraunami duomenys..." : noData ? "Nėra duomenų" : "Atsisiųsti duomenis"}
        </Typography>
        <FileDownloadIcon />
      </IconButton>
    </Box>
  );
};

export default BandosStrukturaPagalKarvesExport;
