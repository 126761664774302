export const urlSuParametrais = (handler, paramaters) => {
  let url = handler;
  const params = new URLSearchParams();

  for (let i = 0; i < paramaters.length; i++) {
    const { parameter, condition, append, value } = paramaters[i];

    // Check the condition using eval (be careful with this approach)
    let conditionMet = false;

    if (condition === "false") {
      conditionMet = parameter === false;
    } else if (condition === "add"){
      conditionMet = true;
    } else if (condition === "true") {
      conditionMet = parameter === true;
    } else if (condition.includes("!==")) {
      const valueToCompare = condition.split("!==")[1].trim();
      conditionMet = parameter !== Number(valueToCompare);
    } else if (condition.includes("===")) {
      const valueToCompare = condition.split("===")[1].trim();
      conditionMet = parameter === Number(valueToCompare);
    } else if(condition.includes("!null")) {
      conditionMet = parameter;
    }

    if (conditionMet) {
      params.append(append, value);
    }
  }

  const queryString = params.toString();

  if (queryString) {
    url += `?${queryString}`;
  }

  return url;
};
