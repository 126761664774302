import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledPaper } from '../component/shared/StyledComponents';
import MitybosPasirinkimai from '../component/mitybosVertinimas/MitybosPasirinkimai';
import {
  LaktacijuAmziai,
  LaktacijuTrukmes,
} from '../constants/ParametruGrupes';
import { useAuth } from '../context/AuthProvider';
import { useMenu } from '../context/MenuProvider';
import { kontrolesPeriodai } from '../constants/KontrolesPeriodai';
import MitybosGrafikas from '../component/mitybosVertinimas/MitybosGrafikas';
import { getItem, postItem } from '../Api/Api';
import { filtruotiGrupes } from './Home';
import MitybosSuvestine from '../component/mitybosVertinimas/MitybosSuvestine';
import MitybosKarviuLentele from '../component/mitybosVertinimas/MitybosKarviuLentele';
import { MitybosRodikliai } from '../constants/Rodikliai';
import { MitybosRBaprasymai, MitybosUraprasymai } from '../constants/Aprasymai';

export const MitybosBaltGrupes = [
  { id: 1, label: 'A1' },
  { id: 2, label: 'A2' },
  { id: 3, label: 'A3' },
  { id: 4, label: 'B1' },
  { id: 5, label: 'B2' },
  { id: 6, label: 'B3' },
  { id: 7, label: 'C1' },
  { id: 8, label: 'C2' },
  { id: 9, label: 'C3' },
];

export const SkaiciuBox = ({ skaicius, tekstas }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
      <Typography align='center'>
        <b>{isNaN(skaicius) ? 0 : skaicius}</b>
      </Typography>
      <Typography align='center'>{tekstas}</Typography>
    </Box>
  );
};

const MitybosVertinimas = () => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === 'Ūkis';

  const [isLoading, setIsLoading] = useState(false);
  const [rodiklis, setRodiklis] = useState(MitybosRodikliai[0]);
  const [karviuSk, setKarviuSk] = useState({
    viso: 0,
    melziamu: 0,
  });
  const [duomenys, setDuomenys] = useState([]);
  const [bandos, setBandos] = useState([]);
  const [menesis, setMenesis] = useState(kontrolesPeriodai[0]);
  const [grupes, setGrupes] = useState(MitybosBaltGrupes.map((g) => g.id));
  const [laktacijos, setLaktacijos] = useState(
    LaktacijuAmziai.map((g) => g.id)
  );
  const [veisles, setVeisles] = useState([]);
  const [laktTrukmes, setLaktTrukmes] = useState(
    LaktacijuTrukmes.map((t) => t.id)
  );

  const grupiuAprasymai =
    rodiklis.parametras === 'rb' ? MitybosRBaprasymai : MitybosUraprasymai;

  useEffect(() => {
    if ((menu.savininkas !== '' || arSavininkas) && menesis != null) {
      const body = { menesis: menesis, bandos: bandos };
      !arSavininkas && (body.gptKodas = menu.savininkas);

      postItem('mityba/skaiciai', body)
        .then((data) => {
          data.data && setKarviuSk(data.data);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            auth.logout();
          }
        });
    }
  }, [menu.savininkas, arSavininkas, menesis, auth, bandos]);

  useEffect(() => {
    if ((menu.savininkas !== '' || arSavininkas) && menesis != null) {
      setIsLoading(true);
      const url = `mityba/karves?menuo=${menesis}`.concat(
        arSavininkas ? '' : `&savininkas=${menu.savininkas}`
      );
      getItem(url)
        .then((data) => {
          data.data && setDuomenys(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setDuomenys([]);
          if (err.response.status === 401) {
            auth.logout();
          }
        });
    }
  }, [menu.savininkas, arSavininkas, menesis, auth]);

  const veisliuList = [...new Set(duomenys.map((d) => d.veisle))];
  let filtruota = duomenys;

  bandos.length > 0 &&
    (filtruota = filtruota.filter((d) => bandos.indexOf(d.banda) > -1));

  veisles.length > 0 &&
    veisles.length < veisliuList.length &&
    (filtruota = filtruota.filter((d) => veisles.indexOf(d.veisle) > -1));

  //Filtruojam laktaciju grupes
  //laktacijos.length > 0 &&
  laktacijos.length < LaktacijuAmziai.length &&
    (filtruota = filtruotiGrupes(
      laktacijos,
      LaktacijuAmziai,
      filtruota,
      'laktAmzius'
    ));

  //Filtruojam laktaciju trukmes
  //laktTrukmes.length > 0 &&
  laktTrukmes.length < LaktacijuTrukmes.length &&
    (filtruota = filtruotiGrupes(
      laktTrukmes,
      LaktacijuTrukmes,
      filtruota,
      'laktDiena'
    ));

  const filtruotaBeGrupiu = filtruota;

  if (grupes.length === 0) {
    filtruota = [];
  } else if (grupes.length < MitybosBaltGrupes.length) {
    if (rodiklis.parametras === 'rb') {
      filtruota = filtruota.filter((d) => grupes.indexOf(d.grId) > -1);
    } else {
      filtruota = filtruota.filter((d) => grupes.indexOf(d.grIdUr) > -1);
    }
  }

  return (
    <>
      <Container maxWidth='100vw' sx={{ mt: 4, mb: 4 }}>
        <Typography variant='h4' sx={{ marginBottom: '20px' }}>
          Bandos mitybos vertinimas
        </Typography>
        <Grid container spacing={1}>
          <Grid
            container
            item
            justifyContent='center'
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Grid item xs={12} lg={12}>
              <StyledPaper
                row='true'
                sx={{ gap: '30px', justifyContent: 'center' }}
              >
                <SkaiciuBox
                  skaicius={karviuSk.viso}
                  tekstas='Karvių sk. bandoje'
                />
                <SkaiciuBox
                  skaicius={karviuSk.melziamu}
                  tekstas='Melžiamų karvių sk.'
                />
                <SkaiciuBox
                  skaicius={isLoading ? '--' : filtruota.length}
                  tekstas='Pasirinktų karvių sk.'
                />
              </StyledPaper>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent='center'
            spacing={2}
            sx={{ mb: 2 }}
          >
            <Grid item xs={12} lg={2}>
              <StyledPaper sx={{ height: '100%' }}>
                <MitybosPasirinkimai
                  bandos={bandos}
                  setBandos={setBandos}
                  menesis={menesis}
                  setMenesis={setMenesis}
                  grupes={grupes}
                  setGrupes={setGrupes}
                  laktacijos={laktacijos}
                  setLaktacijos={setLaktacijos}
                  veisliuList={veisliuList}
                  veisles={veisles}
                  setVeisles={setVeisles}
                  laktTrukmes={laktTrukmes}
                  setLaktTrukmes={setLaktTrukmes}
                  rodiklis={rodiklis.pavadinimas}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <StyledPaper>
                <MitybosGrafikas
                  isLoading={isLoading}
                  duomenys={filtruota}
                  rodiklis={rodiklis}
                  setRodiklis={setRodiklis}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <StyledPaper sx={{ height: '100%' }}>
                <MitybosSuvestine
                  isLoading={isLoading}
                  duomenys={filtruotaBeGrupiu}
                  rodiklis={rodiklis.parametras}
                />
              </StyledPaper>
            </Grid>
          </Grid>
          <Grid
            container
            item
            justifyContent='center'
            spacing={2}
            sx={{ mb: 4 }}
          >
            <Grid item xs={12} lg={8}>
              <StyledPaper style={{ height: 360 }}>
                <MitybosKarviuLentele
                  duomenys={filtruota}
                  rodiklis={rodiklis.parametras}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={12} lg={4}>
              <StyledPaper style={{ height: 360 }}>
                <Typography>
                  {rodiklis.parametras === 'rb'
                    ? 'Pasirinktos R/B ir B(%) grupės aprašymas'
                    : 'Pasirinktos B(%) ir U(mg%) grupės aprašymas'}
                </Typography>
                {grupes.length !== 1 ? (
                  <Typography sx={{ fontSize: '14px' }}>
                    Aprašymas pateikiamas tik pasirinkus vieną grupę
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '14px',
                      height: '100%',
                      overflowY: 'scroll',
                      scrollbarWidth: 'thin',
                    }}
                  >
                    <b>{grupiuAprasymai[grupes[0] - 1].grupe}</b>
                    <br />
                    <b>Priežastys ir požymiai:</b>
                    <br />
                    {grupiuAprasymai[grupes[0] - 1].apibendrinimas && (
                      <>
                        <b>{grupiuAprasymai[grupes[0] - 1].apibendrinimas}</b>
                        <br />
                      </>
                    )}
                    {grupiuAprasymai[grupes[0] - 1].aprasymas}
                  </Typography>
                )}
              </StyledPaper>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default MitybosVertinimas;
