/**
  * Funkcija skirta sumappinti duomenis, kurie yra pateikti sąrašo formatu.
  * Ji apskaičiuoja kiekvieno objekto reikšmių procentinę dalį nuo bendros sumos
  * ir sujungia gautus rezultatus su papildomais duomenimis.
  * [
  *   {
  *     "...": "...",
  *     "...": "...",
  *     "...": {
  *       "***": "***",
  *       "***": "***"
  *     }
  *   },
  *   {
  *     "...": "...",
  *     "...": "...",
  *     "...": {
  *       "***": "***",
  *       "***": "***"
  *     }
  *   },
  * ]
  * @param {Array} duomenys - Pradiniai duomenys, kuriuose yra objektai su nurodytu stulpeliu.
  * @param {string} stulpelis - Laukas objekte, kurio reikšmės turi būti apdorojamos.
  * @param {Array} papildomiDuomenys - Papildomi duomenys, prie kurių bus prijungti apdoroti procentiniai duomenys.
  * @returns {Array} - Apdorotų duomenų masyvas, sujungtas su papildomais duomenimis.
*/
export const mappintiSarasoDuomenis = (duomenys, stulpelis, papildomiDuomenys) => {
  const data = duomenys && duomenys.flatMap((item) => item[`${stulpelis}`]);

  const dataProc = data
    .map((obj) => {
      let totalSum = Object.values(obj).reduce((sum, value) => sum + value, 0);

      // grazinti 1, nes paskui skaiciuojamas procentalus kiekis, 0 sugadins viska ir
      // gaunamos reiksmes bus NaN. grazinus null grafikas vaizdavimo metu pasistumia per
      // tiek menesiu, kiek truksta duomenu.
      if (totalSum <= 0) {
        totalSum = 1;
      }

      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, ((value / totalSum) * 100).toFixed(2)])
      );
    })
    .filter(Boolean); // pasalina nulus

  // Instead of checking every object
  const allSumsGreaterThanZero = dataProc.some(
    (obj) => Object.values(obj).reduce((sum, value) => sum + Number(value), 0) > 0
  );

  const dataParts =
    allSumsGreaterThanZero &&
    dataProc !== null &&
    dataProc.length !== 0 &&
    papildomiDuomenys !== null
      ? papildomiDuomenys.map((item, index) => ({
          ...item,
          ...dataProc[index],
        }))
      : [];

  return dataParts;
};


/**
 * Funkcija skirta sumappinti duomenis iš vieno objekto lauko į naują lauką ir sujungti su papildomais duomenimis.
 * Ji atlieka filtravimą pagal tai, ar reikšmė yra 0 arba didesnė, ir suformuoja naują duomenų struktūrą.
 *
 * @param {Array} duomenys - Pradiniai duomenys, kuriuose yra objektai su nurodytu stulpeliu.
 * @param {string} stulpelis - Laukas objekte, kurio reikšmės turi būti apdorojamos.
 * @param {string} naujasStupelis - Pavadinimas naujam laukui, kuriame bus išsaugotos reikšmės.
 * @param {Array} papildomiDuomenys - Papildomi duomenys, prie kurių bus prijungti apdoroti duomenys.
 * @returns {Array} - Apdorotų duomenų masyvas, sujungtas su papildomais duomenimis.
 */
export const mappintiDuomenis = (duomenys, stulpelis, naujasStupelis, papildomiDuomenys) => {
  if (!duomenys) return [];

  const data = duomenys
    .filter((item) => Number(item[stulpelis]) >= 0)
    .map((item) => ({ [naujasStupelis]: item[stulpelis] }));

  const dataParts = papildomiDuomenys.map((item, index) => ({
    ...item,
    ...(data[index] || {}),
  }));

  return dataParts;
};

/**
 * Funkcija skirta sumappinti duomenis iš kelių stulpelių ir sujungti su papildomais duomenimis.
 * Ji leidžia pasirinkti kelis laukus iš pradinio objekto, priskirti jiems naujus pavadinimus
 * ir gautus duomenis sujungti su papildomais duomenimis.
 *
 * @param {Array} data - Pradiniai duomenys, kuriuose yra objektai su nurodytu stulpeliu.
 * @param {string} column - Stulpelis, pagal kurį filtruojami duomenys (tik su reikšmėmis >= 0).
 * @param {Array<string>} columnsToTake - Stulpeliai, kuriuos reikia paimti iš pradinio objekto.
 * @param {Array<string>} newColumns - Nauji stulpelių pavadinimai, kurie atitiks `columnsToTake`.
 * @param {Array} papildomiDuomenys - Papildomi duomenys, prie kurių bus prijungti apdoroti duomenys.
 * @returns {Array} - Apdorotų duomenų masyvas, sujungtas su papildomais duomenimis.
 */
export const mappintiDuomenisSuDaugStulpeliu = (
  data,
  column,
  columnsToTake,
  newColumns,
  papildomiDuomenys
) => {
  if (!data) return [];

  // patikrina ar yra duomenu, gali buti ir nuliai, nes kitaip pasistumia grafikas
  // i tas vietas kur nera duomenu.
  const temp1 = data.filter((item) => Number(item[column]) >= 0);

  const temp2 = temp1.map((item) => {
    let transformedItem = {}; // saugot reiksmem

    columnsToTake.forEach((col, index) => {
      transformedItem[newColumns[index]] = item[col]; // dinamiskai prijungia raktus
    });

    return transformedItem;
  });

  // sujungia duomenis
  const dataParts = papildomiDuomenys.map((item, index) => ({
    ...item,
    ...(temp2[index] || {}), // jei nera duomenu, ideda tuscia
  }));

  return dataParts;
};

export const mappintDuomenisIsObjektuIrSujungti = (data, stulpeliai, naujiStulpeliai) =>{
  let returnArray = []
  for (let i = 0; i < stulpeliai.length; i++){
    const senasStulpelis = stulpeliai[i];
    const naujasStulpelis = naujiStulpeliai[i];

    let tempArray = data[senasStulpelis]
    ? {name: naujasStulpelis, count: data[senasStulpelis]}
    : {name: naujasStulpelis, count: 0};
    returnArray.push(tempArray);
  }

  return returnArray;
}
