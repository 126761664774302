import { Box, Slider, styled, Typography } from "@mui/material";
import MuiInput from "@mui/material/Input";
import { useEffect, useState } from "react";
import { useDebounce } from "../../hooks/useDebounce";

function valuetext(value) {
  return `${value}`;
}

const Input = styled(MuiInput)`
  width: 42px !important;
`;

const Slankjuoste = ({ min, max, label, numatytoji, marks, zingsnis, changeValue }) => {
  const [value, setValue] = useState(numatytoji);
  const [inputValue, setInputValue] = useState(numatytoji);

  // Debounce slider value to prevent constant updates while dragging
  const debouncedSliderValue = useDebounce(value, 500);

  // Debounce input value separately
  const debouncedInputValue = useDebounce(inputValue, 500);

  // Update parent state when slider value changes (after debounce)
  useEffect(() => {
    changeValue(debouncedSliderValue);
  }, [debouncedSliderValue, changeValue]);

  // Handle input value changes (after debounce)
  useEffect(() => {
    // Only update if the values are different
    if (debouncedInputValue !== value) {
      setValue(debouncedInputValue);
      // No need to call changeValue here as it will be triggered by the other effect
    }
  }, [debouncedInputValue]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setInputValue(newValue);
    // Don't call changeValue here - let the debounced effect handle it
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value === "" ? "" : Number(event.target.value));
    // Set input value without validation (validation happens on blur)
  };

  const handleBlur = () => {
    let newValue = Number(inputValue);
    if (isNaN(newValue) || inputValue === "") {
      newValue = min;
    } else if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }

    // Update both local state and input value
    setValue(newValue);
    setInputValue(newValue);
    // Immediately update parent to ensure consistency
    changeValue(newValue);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "-10px",
        width: "100%",
        height: "100%",
      }}
    >
      <Typography>{label}</Typography>
      <Box
        sx={{
          width: "100%",
          paddingLeft: "10px",
          marginTop: "-5px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box width="calc(100% - 65px)">
            <Slider
              getAriaLabel={() => label}
              value={value}
              defaultValue={numatytoji}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              min={min}
              max={max}
              marks={marks}
              step={zingsnis}
            />
          </Box>
          <Box>
            <Input
              value={inputValue}
              size="small"
              onChange={handleInputChange}
              onBlur={handleBlur}
              inputProps={{
                step: zingsnis,
                min: min,
                max: max,
                type: "number",
                "aria-labelledby": "input-slider",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Slankjuoste;
