import React from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { PapildomosSpalvos, Primary } from "../../constants/Spalvos";
import DuomenysKraunami from "../shared/DuomenysKraunami";
import DuomenuNera from "../shared/DuomenuNera";
import { Typography } from "@mui/material";

// Patikrina ar visuose duomenyse yra daugiau nei raktinis stulpelis ir ar tie duomenys nera tusti
function arYraDuomenuMasyve(obj, raktas) {
  const keys = Object.keys(obj);

  if (keys.length === 1 && keys.includes(raktas)) {
    return false;
  }

  return keys.every((key) => key === raktas || obj[key] !== null);
}

function objektuRikiavimas(arr) {
  return arr.map((obj) => {
    // surikiuoja didejimo tvarka
    let sortedEntries = Object.entries(obj).sort(
      ([, val1], [, val2]) => (val2 ?? -Infinity) - (val1 ?? -Infinity)
    );

    // sudeda atgal i sarasa
    return { ...Object.fromEntries(sortedEntries) };
  });
}

const BarColors = [Primary, "#8e8989", "#3d3e40", "#060606"];

/**
 * Komponentas vertikaliems stulpeliniams grafikams, su skirtingom reiksmem
 * dabar pritaikyta procentams, esant reikalui galima perdaryti
 * @param {boolean} isLoading pozymis, kuris parodo ar reikia rodyti, kad duomenys kraunami
 * @param {string} pavadinimas tekstas virs grafiko
 * @param {[]} duomenys duomenu masyvas, struktura
 * [
 *    {name: 'Pavadinimas1', value1: 10, value2: 5, ...},
 *    {name: 'Pavadinimas2', value1: 15, value2: 2, ...},
 *    ...
 * ]
 * Tokiu atveju butu 2 stulpeliai Pavadinimas1 ir Pavadinimas2 ir stulpeliuose
 * butu tiek skirtingu reiksmiu, kiek yra value parametru json objekte
 * https://recharts.org/en-US/examples/StackedBarChart
 * @param {string} raktas json raktas, pagal kuri rodysim skirtingus stulpelius
 * @returns
 */
const StrukturosStulpGrafikas = ({ isLoading, pavadinimas, duomenys, raktas }) => {
  if (duomenys === undefined) return null;

  duomenys = duomenys.filter((item) => arYraDuomenuMasyve(item, raktas));

  if (isLoading) {
    return <DuomenysKraunami pavadinimas={pavadinimas} />;
  }

  if (duomenys.length === 0) {
    return <DuomenuNera pavadinimas={pavadinimas} />;
  }

  duomenys = objektuRikiavimas(duomenys, raktas);

  //Susirenkam visus json raktus, iskyrus nurodyta
  const keys = [...new Set(duomenys.flatMap(Object.keys))].filter((d) => d !== raktas);
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={duomenys} layout="vertical" width="100%" height="100%">
          <CartesianGrid />
          <Tooltip />
          <Legend />
          {/* Jeigu ateityje reikes perdarineti, kad veiktu ne tik procentams,
                tai reik ismesti domain prop */}
          <XAxis
            type="number"
            unit="%"
            domain={[0, 100]}
            allowDecimals={false}
            ticks={[0, 25, 50, 75, 100]}
          />
          <YAxis dataKey={raktas} type="category" style={{ fontSize: 15 }} />
          {keys.map((k, index) => (
            <Bar
              key={`bar-${index}`}
              dataKey={k}
              stackId="a"
              fill={keys.length >= 5 ? PapildomosSpalvos[index] : BarColors[index]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default StrukturosStulpGrafikas;
