import { Box, Typography } from "@mui/material";

const DuomenysKraunami = ({ pavadinimas }) => {
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Box
        width="100%"
        height="100%"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>Duomenys kraunami...</Typography>
      </Box>
    </>
  );
};

export default DuomenysKraunami;
