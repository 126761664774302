const Ranges = [
  { max: 0.75, ticks: [0, 0.25, 0.5, 0.75], domain: 0.8 },
  { max: 1, ticks: [0, 0.25, 0.5, 0.75, 1], domain: 1.1 },
  { max: 2.5, ticks: [0, 0.5, 1, 1.5, 2, 2.5], domain: 2.2 },
  { max: 5, ticks: [0, 1, 3, 5], domain: 5.5 },
  { max: 7.5, ticks: [0, 1, 3, 5, 7], domain: 7.7 },
  { max: 10, ticks: [0, 5, 10], domain: 11 },
  { max: 20, ticks: [0, 5, 10, 15, 20], domain: 22 },
  { max: 25, ticks: [0, 5, 10, 15, 20, 25], domain: 26 },
  { max: 50, ticks: [0, 10, 30, 50], domain: 52 },
  { max: 75, ticks: [0, 25, 50, 75], domain: 77 },
];

export default Ranges;