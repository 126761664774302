import React from 'react';
import { useAuth } from '../../context/AuthProvider';
import { useMenu } from '../../context/MenuProvider';
import { useGetQuery } from '../../hooks/useQuery';
import { kontrolesPeriodai } from '../../constants/KontrolesPeriodai';
import { Box } from '@mui/material';
import SingleSelect from '../shared/SingleSelect';

/**
 * Bandros strukturos filtru komponentas
 * @param {string} menesis pasirinktas menesis
 * @param {() => {}} setMenesis menesio keitimo funkcija
 * @param {number} banda pasirinkta banda
 * @param {() => {}} setBanda bandos keitimo funkcija
 * @param {number} veisle pasirinkta veisle
 * @param {() => {}} setVeisle veisles keitimo funkcija
 * @returns
 */
const StrukturosPasirinkimai = ({ menesis = null, setMenesis = null, banda = null, setBanda = null, veisle = null, setVeisle = null, visosBandos = true }) => {
  const auth = useAuth();
  const menu = useMenu();
  const arSavininkas = auth.user.role === 'Ūkis';

  const banduList = useGetQuery(
    arSavininkas ? 'bandos/visos' : 'bandos'.concat(visosBandos ? '/visos' : '' )  + `/${menu.savininkas}`
  );
  const bandos = banduList.map((b) => ({
    id: b.banda,
    label: String(b.banda),
  }));
  bandos.unshift({ id: 0, label: 'Visos' });

  const veisliuList = useGetQuery(
    arSavininkas ? 'savininkai/veisles' : `savininkai/veisles/${menu.savininkas}`
  );

  const veisles = veisliuList.map((v) => ({
    id: v.Id,
    label: v.Pavadinimas
  }))
  veisles.unshift({ id: 0, label: 'Visos' });

  const menesiai = kontrolesPeriodai.map((k) => {
    return { id: k, label: k };
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        width: '100%',
        height: '100%'
      }}
    >
      {menesis !== null && (
        <Box key='menesis'>
          <SingleSelect
            pavadinimas='Metai ir mėnuo'
            pasirinkimai={menesiai}
            reiksme={menesis}
            changeReiksme={setMenesis}
          />
        </Box>
      )}
      {banda !== null && (
        <Box key='banda'>
          <SingleSelect
            pavadinimas='Banda'
            pasirinkimai={bandos}
            reiksme={banda}
            changeReiksme={setBanda}
          />
        </Box>
      )}
      {veisle !== null && (
        <Box key='veisle'>
          <SingleSelect
            pavadinimas='Veislė'
            pasirinkimai={veisles}
            reiksme={veisle}
            changeReiksme={setVeisle}
          />
        </Box>
      )}
    </Box>
  );
};

export default StrukturosPasirinkimai;
