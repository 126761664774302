import { useEffect, useState } from "react";
import { useAuth } from "../context/AuthProvider";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { StyledPaper } from "../component/shared/StyledComponents";
import { SkaiciuBox } from "./MitybosVertinimas";
import SveikatSkritDiagrama from "../component/Sveikatingumas/SveikatSkritDiagrama";
import StrukturosStulpGrafikas from "../component/BandosStruktura/StrukturosStulpGrafikas";
import StrukturosPasirinkimai from "../component/BandosStruktura/StrukturosPasirinkimai";
import { kontrolesPeriodai } from "../constants/KontrolesPeriodai";
import { useMenu } from "../context/MenuProvider";
import { getItem } from "../Api/Api";
import BandosStrukturaPagalKarvesExport from "../component/BandosStruktura/BandosStrukturaPagalKarvesExport";
import { PapildomosSpalvos } from "../constants/Spalvos";
import { urlSuParametrais } from "../functions/urlSuParametrais";
import {
  mappintDuomenisIsObjektuIrSujungti,
  mappintiDuomenis,
  mappintiSarasoDuomenis,
} from "../functions/duomenuMappinimas";

const BandosStruktura = () => {
  const auth = useAuth();
  const menu = useMenu();

  const [isLoading, setIsLoading] = useState(false);
  const [menesis, setMenesis] = useState(kontrolesPeriodai[0]);
  const [bandos, setBandos] = useState(0);
  const [duomenys, setDuomenys] = useState([]);

  const [fetchTrigger, setFetchTrigger] = useState(0);

  const handleFetch = () => {
    setFetchTrigger((prev) => prev + 1); // Incrementing ensures a fresh trigger every time
  };

  const arSavininkas = auth.user.role === "Ūkis";

  useEffect(() => {
    if ((menu.savininkas !== "" || arSavininkas) && menesis != null) {
      setIsLoading(true);

      const params = [
        { parameter: menesis, condition: "!null", append: "menuo", value: menesis },
        { parameter: bandos, condition: "!==0", append: "banda", value: bandos },
        {
          parameter: arSavininkas,
          condition: "false",
          append: "savininkas",
          value: menu.savininkas,
        },
      ];

      getItem(urlSuParametrais("savininkai/banda/struktura", params))
        .then((data) => {
          data.data && setDuomenys(data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setDuomenys([]);
          if (err.response.status === 401) {
            auth.logout();
          }
        });
    }
  }, [menu.savininkas, arSavininkas, auth, menesis, bandos]);

  const data = duomenys;

  const bandosSudetis = mappintDuomenisIsObjektuIrSujungti(
    data,
    ["karviu", "telyciu"],
    ["Karvių", "Telyčių"]
  );

  const telyciuAmziai = data.telyciuAmziai ? data.telyciuAmziai : [];
  const laktacijos = data.laktacijos ? data.laktacijos : [];
  const kkSkyriai = data.kkSkyriai ? data.kkSkyriai : [];
  const veisles = data.veisles ? data.veisles : [];

  const karviu = data.karviu;
  const telyciuLaktAmzius = data.laktAmzius;
  const pirmaVersiu = data.pirmaversiu;
  const svieziu = data.svieziu;

  useEffect(() => {
    setBandos(0);
  }, [menu.savininkas]);

  return (
    <>
      <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
        <Grid container>
          <Grid item lg={10}>
            <Typography variant="h4" sx={{ marginBottom: "20px" }}>
              Bandos struktūra
            </Typography>
          </Grid>
          <Grid item xs={12} lg={2} onClick={handleFetch}>
            <BandosStrukturaPagalKarvesExport
              onClick={fetchTrigger}
              menesis={menesis}
              bandos={bandos}
              arSavininkas={arSavininkas}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={12}>
            <StyledPaper
              row="true"
              sx={{
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid container>
                <Grid item md={3} xs={6}>
                  <SkaiciuBox skaicius={karviu} tekstas="Karvių sk." />
                </Grid>
                <Grid item md={3} xs={6}>
                  <SkaiciuBox skaicius={telyciuLaktAmzius} tekstas="Vid. amžius laktacijomis" />
                </Grid>
                <Grid item md={3} xs={6}>
                  <SkaiciuBox skaicius={pirmaVersiu} tekstas="Pirmaveršių karvių sk." />
                </Grid>
                <Grid item md={3} xs={6}>
                  <SkaiciuBox
                    skaicius={svieziu}
                    tekstas="Šviežiapienių karvių sk. (45 d. po apsiveršiavimo)"
                  />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>

          <Grid item container columns={14} spacing={2}>
            <Grid item xs={14} lg={2}>
              <StyledPaper sx={{ height: "100%" }}>
                <StrukturosPasirinkimai
                  menesis={menesis}
                  setMenesis={setMenesis}
                  banda={bandos}
                  setBanda={setBandos}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={14} lg={4}>
              <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
                <SveikatSkritDiagrama
                  isLoading={isLoading}
                  pavadinimas="Karvių ir telyčių dalys bandoje"
                  duomenys={bandosSudetis}
                  raktas={"count"}
                  startAngle={130}
                  endAngle={490}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={14} lg={4}>
              <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
                <SveikatSkritDiagrama
                  isLoading={isLoading}
                  pavadinimas="Telyčių skaičius pagal amžių (mėnesiais)"
                  duomenys={telyciuAmziai}
                  raktas={"count"}
                  spalvos={PapildomosSpalvos}
                  startAngle={105}
                  endAngle={465}
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={14} lg={4}>
              <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
                <SveikatSkritDiagrama
                  isLoading={isLoading}
                  pavadinimas=" Karvių skačius pagal laktacijos amžių"
                  duomenys={laktacijos}
                  raktas={"count"}
                  spalvos={PapildomosSpalvos}
                />
              </StyledPaper>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} lg={2}>
            <StyledPaper sx={{ height: "100%" }}>
              <StrukturosPasirinkimai
                menesis={menesis}
                setMenesis={setMenesis}
                banda={bandos}
                setBanda={setBandos}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={3}>
            <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
              <SveikatSkritDiagrama
                isLoading={isLoading}
                pavadinimas="Karvių ir telyčių dalys bandoje"
                duomenys={bandosSudetis}
                raktas={"count"}
                startAngle={130}
                endAngle={490}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={3}>
            <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
              <SveikatSkritDiagrama
                isLoading={isLoading}
                pavadinimas="Telyčių skaičius pagal amžių (mėnesiais)"
                duomenys={telyciuAmziai}
                raktas={"count"}
                spalvos={PapildomosSpalvos}
                startAngle={105}
                endAngle={465}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
              <SveikatSkritDiagrama
                isLoading={isLoading}
                pavadinimas=" Karvių skačius pagal laktacijos amžių"
                duomenys={laktacijos}
                raktas={"count"}
                spalvos={PapildomosSpalvos}
              />
            </StyledPaper>
          </Grid> */}
          <Grid item xs={12} lg={6}>
            <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
              <StrukturosStulpGrafikas
                isLoading={isLoading}
                pavadinimas={"Bandos struktūra pagal KK skyrius %"}
                duomenys={kkSkyriai}
                raktas={"lytis"}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={6}>
            <StyledPaper sx={{ height: "100%", minHeight: "350px" }}>
              <StrukturosStulpGrafikas
                isLoading={isLoading}
                pavadinimas={"Bandos struktūra pagal veisles %"}
                duomenys={veisles}
                raktas={"lytis"}
              />
            </StyledPaper>
          </Grid>

          {/* HERE IS SOME CODE TO REMOVE */}
        </Grid>
      </Container>
    </>
  );
};

export default BandosStruktura;
