import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Tooltip as ReactToolTip } from "react-tooltip";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from "recharts";
import { PapildomosSpalvos, Spalvos } from "../../constants/Spalvos";
import { StyledLabel, StyledTooltip } from "../shared/StyledComponents";
import DuomenysKraunami from "../shared/DuomenysKraunami";
import DuomenuNera from "../shared/DuomenuNera";

// Patikrina ar visuose duomenyse yra daugiau nei raktinis stulpelis ir ar tie duomenys nera tusti
function arYraDuomenuMasyve(obj) {
  const keys = Object.keys(obj);

  if (keys.length === 1) {
    return false;
  }

  return keys.every((key) => obj[key] !== null);
}

const CustomLegend = ({ payload }) => {
  const [touchedItem, setTouchedItem] = useState(null);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const [isVisible, setIsVisible] = useState(true); // State to control visibility

  useEffect(() => {
    const checkTouchDevice = () => {
      setIsTouchDevice(window.matchMedia("(hover: none)").matches);
    };

    checkTouchDevice();
    window.addEventListener("resize", checkTouchDevice);

    return () => window.removeEventListener("resize", checkTouchDevice);
  }, []);

  useEffect(() => {
    if (isTouchDevice && touchedItem !== null) {
      const timer = setTimeout(() => {
        setIsVisible(false); // Hide the legend after 5 seconds
      }, 5000);

      return () => {
        clearTimeout(timer);
        setIsVisible(true);
      }; // Cleanup timer on unmount
    }
  }, [isTouchDevice, touchedItem]);

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          // flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {payload.map((entry, index) => (
          <Box
            key={index}
            data-tooltip-id="legend-tooltip"
            data-tooltip-content={entry.value}
            style={{
              display: "flex",
              alignItems: "center",
              // cursor: "pointer",
              margin: "0 8px",
              overflow: "hidden",
              ...(isTouchDevice && { touchAction: "manipulation" }),
            }}
            onClick={() => {
              if (isTouchDevice) {
                setTouchedItem(touchedItem === index ? null : index);
              }
            }}
          >
            {/* Legend Color Box */}
            <Box
              style={{
                width: 12,
                height: 12,
                backgroundColor: entry.color,
                borderRadius: 3,
                marginRight: 5,
              }}
            />
            {/* Truncated Text */}
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {entry.value.length > 33 ? `${entry.value.substring(0, 33)}...` : entry.value}{" "}
              {/* {entry.value.split(" ").length > 5
                ? `${entry.value.split(" ").slice(0, 5).join(" ")}...`
                : entry.value} */}
            </span>
            {isVisible && isTouchDevice && touchedItem === index && (
              <Box
                style={{
                  position: "absolute",
                  top: "-40px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  backgroundColor: "#fff",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                  whiteSpace: "normal",
                  wordBreak: "normal",
                  textAlign: "center",
                  zIndex: 9999,
                }}
              >
                {entry.value}
              </Box>
            )}
          </Box>
        ))}
      </Box>

      {/* Single tooltip for all legend items */}
      <ReactToolTip
        id="legend-tooltip"
        place="top"
        effect="solid"
        delayShow={300}
        style={{
          zIndex: 9999,
          pointerEvents: "none",
        }}
        float={true}
      />
    </Box>
  );
};

const CustomTooltip = (pavadinimasLegendoje, pavadinimasGrafike) => (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`${payload[0].payload[`${"Mėnesis"}`]}`}</StyledLabel>
        {payload.map((item, index) => {
          if (item.name === pavadinimasLegendoje) {
            let stringEnd = pavadinimasGrafike.slice(-6);
            return (
              <StyledLabel color={item.color} key={index}>{`${pavadinimasGrafike.slice(0, -6)}: ${
                item.value
              } ${stringEnd}`}</StyledLabel>
            );
          } else {
            const percentageKey = `${item.name} %`; // Construct percentage key
            return (
              <StyledLabel color={item.color} key={index}>
                {`${item.name}: ${item.value} (${item.payload[percentageKey]}%)`}
              </StyledLabel>
            );
          }
        })}
      </StyledTooltip>
    );
  }

  return null;
};

const CustomTooltip2 = (pavadinimasLegendoje, pavadinimasGrafike) => (props) => {
  const { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <StyledLabel>{`${payload[0].payload[`${"Mėnesis"}`]}`}</StyledLabel>
        {payload.map((item, index) => {
          if (item.name === pavadinimasLegendoje) {
            let stringEnd = pavadinimasGrafike.slice(-6);
            return (
              <StyledLabel color={item.color} key={index}>
                {`${pavadinimasGrafike.slice(0, -6)}: ${item.value} ${stringEnd}`}
              </StyledLabel>
            );
          } else {
            return (
              <StyledLabel
                color={item.color}
                key={index}
              >{`${item.name}: ${item.value}`}</StyledLabel>
            );
          }
        })}
      </StyledTooltip>
    );
  }

  return null;
};

function findGlobalMax(array, raktas, groups) {
  // patikrina ar yra duomenu
  if (!Array.isArray(array) || array.length === 0 || !array[0]) {
    return 0;
  }

  // patikrina ar yra skaitiniu raktu
  const hasNumericKeys =
    groups.length === 0 &&
    Object.keys(array[0]).some((key) => {
      if (key === raktas || key.includes("%")) return false;

      return isNaN(key);
    });
  let globalMax = -Infinity;

  array.forEach((obj) => {
    if (hasNumericKeys) {
      // skaitiniu raktu suma
      let sum = 0;
      for (const key in obj) {
        if (key !== raktas && !key.includes("%")) {
          const value = parseFloat(obj[key] || 0);
          if (!isNaN(value)) {
            sum += value;
          }
        }
      }
      globalMax = Math.max(globalMax, sum);
    } else {
      // grupems
      if (Array.isArray(groups)) {
        // grupiu masyvas
        const groupSums = {};
        groups.forEach((group) => {
          groupSums[group] = 0;
        });

        // kiekvienos grupes suma
        for (const key in obj) {
          if (!key.includes("%")) {
            const value = parseFloat(obj[key] || 0);
            if (!isNaN(value)) {
              // prideda suma grupei kuriai reikia
              for (const group of groups) {
                if (key.startsWith(group)) {
                  groupSums[group] += value;
                  break;
                }
              }
            }
          }
        }
        // grazina globalu max
        globalMax = Math.max(globalMax, ...Object.values(groupSums));
      }
    }
  });

  return globalMax === -Infinity ? 0 : globalMax;
}

function generuotiDiagramosZymas(end, steps) {
  // dalyba is nulio negalima
  if (steps <= 0) return [];

  // paskaiciuoja zingsni. javascript pramanai, kad neimanoma tiesiogiai
  // skaiciaus su Math.round() suapvalinti iki norimo skaiciaus kiekio po
  // kablelio. Todel turint skaiciu pirma ji suapvaliname su toFixed(),
  // bet Kadangi tai pavercia skaiciu i string, tai su Number() ji atverciam
  // atgal i skaiciu
  let stepSize = Number((end / steps).toFixed(0));
  if (steps > end) {
    stepSize = Number((end / steps).toFixed(1));
  }

  const result = [];
  let temp = 0;

  for (let i = 0; i <= steps; i++) {
    i === steps
      ? result.push(Math.max(Number(temp.toFixed(2)), end))
      : result.push(Math.min(Number(temp.toFixed(2)), end));
    temp += stepSize;
  }

  return result;
}

const BandosKitimoGrafikas = ({
  duomenys,
  pavadinimas,
  isLoading,
  xAxis,
  customTooltip = false,
  stacks = [],
  unit = "",
  kontroliniaiDuomenys,
  kontrolineLinija,
  kontrolineLegenda,
  pavadinimasGrafike,
  pavadinimasLegendoje,
  tooltip,
  etikete,
  papildomasResponsive,
}) => {
  const [reikiaEtiketes, setReikiaEtiketes] = useState(true);

  useEffect(() => {
    const updateLegendPosition = () => {
      if (window.innerWidth < 1400 || (papildomasResponsive && window.innerWidth < 1650)) {
        setReikiaEtiketes(false);
      } else {
        setReikiaEtiketes(true);
      }
    };

    updateLegendPosition(); // Set initial state
    window.addEventListener("resize", updateLegendPosition);

    return () => window.removeEventListener("resize", updateLegendPosition);
  }, []);

  if (duomenys === null && duomenys.length === 0 && !duomenys) {
    return null;
  }

  const max = findGlobalMax(duomenys, xAxis, stacks);
  const newMax = Number((max + max / 20).toFixed(0));
  const domain = [0, newMax];
  // const ticks = generuotiDiagramosZymas(newMax, 5);
  const ticks = [];

  const raktai = [...new Set(duomenys.flatMap(Object.keys))].filter((x) => x !== xAxis); // Get all keys
  const filtruotiRaktai = raktai.filter((k) => !k.includes("%"));

  const kontroliniaiRaktai = kontroliniaiDuomenys
    ? [...new Set(kontroliniaiDuomenys.flatMap(Object.keys))].filter((x) => x !== xAxis)
    : null; // Get all keys

  duomenys = duomenys.filter((item) => arYraDuomenuMasyve(item));

  if (isLoading) {
    return <DuomenysKraunami pavadinimas={pavadinimas} />;
  }

  if (duomenys.length === 0) {
    return <DuomenuNera pavadinimas={pavadinimas} />;
  }

  const kontroliniaiDuomenysFiltered =
    kontroliniaiDuomenys &&
    kontroliniaiDuomenys.filter((kontroline) =>
      duomenys.some((data) => data.Mėnesis === kontroline.Mėnesis)
    );

  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <ResponsiveContainer width="100%" minHeight={"200px"}>
        <LineChart
          width={500}
          height={300}
          data={duomenys}
          margin={{
            top: 5,
            right: 9,
            bottom: 5,
            left: 0,
          }}
        >
          <CartesianGrid strokeDasharray="5 5" />
          <XAxis dataKey={xAxis} />
          <YAxis
            domain={domain}
            ticks={ticks.length !== 0 ? ticks : null}
            unit={unit}
            padding={{ top: etikete && reikiaEtiketes ? 20 : 0 }}
          />
          <Tooltip
            cursor={customTooltip ? { strokeDasharray: "3 3" } : null}
            content={
              customTooltip
                ? tooltip === 2
                  ? CustomTooltip2(pavadinimasLegendoje, pavadinimasGrafike)
                  : CustomTooltip(pavadinimasLegendoje, pavadinimasGrafike)
                : null
            }
          />
          <Legend
            align="center"
            content={(props) => (
              <CustomLegend
                {...props}
                payload={[
                  ...filtruotiRaktai.map((k, index) => ({
                    value: k,
                    type: "line",
                    id: k,
                    color: filtruotiRaktai.length >= 5 ? Spalvos[index] : PapildomosSpalvos[index],
                  })),
                  ...(kontroliniaiDuomenysFiltered && kontroliniaiRaktai
                    ? kontroliniaiRaktai.map((k) => ({
                        value: k,
                        type: "line",
                        id: k,
                        color: "red",
                      }))
                    : []),
                  ...(kontrolineLinija
                    ? [
                        {
                          value: kontrolineLegenda,
                          type: "line",
                          id: "kontroline",
                          color: "red",
                          strokeDasharray: "5 5",
                        },
                      ]
                    : []),
                ]}
              />
            )}
          />

          {kontrolineLinija && (
            <ReferenceLine
              y={kontrolineLinija}
              stroke="red"
              strokeDasharray="5 5"
              strokeWidth="5px"
            />
          )}

          {kontroliniaiDuomenysFiltered && kontroliniaiRaktai ? (
            kontroliniaiRaktai.map((k) => (
              <Line
                data={kontroliniaiDuomenysFiltered}
                key={k}
                dataKey={k}
                type="monotone"
                stroke="red"
                strokeDasharray="5 5"
                strokeWidth="5px"
                dot={false}
              />
            ))
          ) : (
            <></>
          )}
          {filtruotiRaktai.map((k, index) => (
            <Line
              key={k}
              type="monotone"
              dataKey={k}
              stroke={filtruotiRaktai.length >= 5 ? Spalvos[index] : PapildomosSpalvos[index]}
              strokeWidth={"3px"}
            >
              {etikete && reikiaEtiketes && (
                <LabelList
                  datakey="k"
                  position="top"
                  offset={10}
                  fill={filtruotiRaktai.length >= 5 ? Spalvos[index] : PapildomosSpalvos[index]}
                />
              )}
            </Line>
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default BandosKitimoGrafikas;
