import React from "react";
import { saveAs } from "file-saver";
import { utils, write } from "xlsx";
import { Box, IconButton, Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Primary } from "../../constants/Spalvos";

export const exportToExcel = ({ data, columns, widths, fileName }) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, "Sheet1");
  utils.sheet_add_aoa(worksheet, [columns], { origin: "A1" });
  widths && (worksheet["!cols"] = widths.map((w) => ({ wch: w })));
  const excelBuffer = write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${fileName}.xlsx`); //57
};

const ExcelJS = require("exceljs");

export const exportToExcelWithRules = ({ data, columns, fileName, formatRules, widths }) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // stulpeliai gali būti tušti, todėl juos pridedame jei nėra
  if (!columns || (columns.length === 0 && data.length > 0)) {
    columns = Object.keys(data[0] || {});
  }

  // sukuria stulpelisu su headeriu excelio faile
  worksheet.columns = columns.map((col, index) => ({
    header: col,
    key: col,
    width: widths ? widths[index] : 12, // Use provided width or default
  }));

  // prideda duomenis
  data.forEach((row) => {
    worksheet.addRow(row);
  });

  // formatavimo taisykles jei tokios yra
  if (formatRules && Array.isArray(formatRules)) {
    worksheet.eachRow((row, rowIndex) => {
      if (rowIndex === 1) return; // skip header

      formatRules.forEach((rule) => {
        const columnIndex = columns.indexOf(rule.column) + 1;
        if (columnIndex === 0) return;

        const cell = row.getCell(columnIndex);

        const controlColumnIndex = columns.indexOf(rule.controlColumn) + 1;
        if (controlColumnIndex === 0) return;

        const controlCell = row.getCell(controlColumnIndex);

        if (rule.condition(cell.value, controlCell.value)) {
          // pakeicia teksto jei to reikia
          if (rule.transformValue) {
            cell.value = rule.transformValue(cell.value);
          }

          // padaro pasvirusi nuraukima per laukeli
          cell.border = {
            ...(cell.border || {}),
            diagonal: {
              up: true,
              style: "thin",
            },
          };
        }
      });
    });
  }

  // sukuria bloba ir parsiuncia faila
  return workbook.xlsx
    .writeBuffer()
    .then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName || "export.xlsx";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    })
    .catch((err) => {
      console.error("Error creating Excel file:", err);
    });
};

/**
 * Komponentas, skirtas sugeneruoti xlsx faila is duoto duomenu masyvo
 * @param {[]} data duomenu masyvas
 * @param {[]} column stulpeliu pavadinimu masyvas
 * @param {[]} widths excelio stulpeliu plociu pavadinimas
 * @param {[]} fileName failo pavadinimas
 * @param {[]} align nuorodos lygivimas, default flex-start
 * @returns
 */
const ExportExcel = ({ data, columns, widths, fileName, align }) => {
  return (
    <Box display="flex" justifyContent={align ? align : "flex-start"}>
      <IconButton
        onClick={() =>
          exportToExcel({
            data: data,
            columns: columns,
            widths: widths,
            fileName: fileName,
          })
        }
        sx={{ color: Primary, padding: 0 }}
      >
        <Typography>Atsisiųsti duomenis</Typography>
        <FileDownloadIcon />
      </IconButton>
    </Box>
  );
};

export default ExportExcel;
