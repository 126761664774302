export const SvieziapieniuMarks = [
  { value: 45, label: "45" },
  { value: 60, label: "60" },
  { value: 75, label: "75" },
  { value: 90, label: "90" },
];

export const SvieziapieniuProcentuMarks = [
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
  { value: 35, label: "35" },
  { value: 40, label: "40" },
];
