import React from "react";
import { Box, Typography } from "@mui/material";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Text, Tooltip } from "recharts";
import { Primary } from "../../constants/Spalvos";
import DuomenuNera from "../shared/DuomenuNera";
import DuomenysKraunami from "../shared/DuomenysKraunami";
import zIndex from "@mui/material/styles/zIndex";

const PieColors = [Primary, "#8e8989", "#3d3e40", "#060606", "#1068f0"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (spalvos) => (props) => {
  const { cx, cy, midAngle, outerRadius, percent, index, payload } = props;
  const radius = outerRadius * 1.2;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (payload.count === 0) return null;

  return (
    <Text
      x={x}
      y={y}
      fill={spalvos ? spalvos[index] : PieColors[index]}
      textAnchor={x > cx ? "start" : "end"}
    >
      {`${payload.count}(${(percent * 100).toFixed(0)}%)`}
    </Text>
  );
};

/**
 * Komponentas, skirtas piesti pie chartus is duotu duomenu su procentais ir legenda,
 * dabar tinka tik kai yra iki 5 grupiu
 * @param {boolean} isLoading pozymis, kuris parodo ar reikia rodyti, kad duomenys kraunami
 * @param {string} pavadinimas tekstas kuri reikia rodyti virs grafiko
 * @param {[]} duomenys duomenu masyvas, formatas [{name: "pavadinimas", count: 7}, {name: "pavadinimas2", count: 2}, ...]
 * @param {string} raktas nurodo koks atributas bus naudojamas kaip raktas. taip pat naudojamas patikrinti ar yra duomenu sarase
 * @param {[]} spalvos nurodo naudoti kitas spalvas
 * @returns {}
 */
const SveikatSkritDiagrama = ({
  isLoading,
  pavadinimas,
  duomenys,
  raktas = null,
  spalvos,
  startAngle,
  endAngle,
}) => {
  if (isLoading) {
    return <DuomenysKraunami pavadinimas={pavadinimas} />;
  }

  if (raktas !== null) {
    let keySum = duomenys.reduce((sum, item) => sum + item[`${raktas}`], 0);
    if (keySum === 0) {
      return <DuomenuNera pavadinimas={pavadinimas} />;
    }
  }

  if (duomenys === undefined || duomenys.length === 0) return null;

  const data = duomenys.filter((d) => d.count > 0);

  // static, relative, fixed, absolute, sticky
  return (
    <>
      <Typography>{pavadinimas}</Typography>
      <Box
        width="100%"
        height="100%"
        display="flex"
        align="center"
        justifyContent="center"
        justifyItems="center"
        top="50%"
        bottom="50%"
        alignItems="center"
      >
        <ResponsiveContainer width="95%" height="95%">
          <PieChart
            margin={{
              left: 20,
              right: 20,
              top: 10,
            }}
            overflow={"visible"}
          >
            <Pie
              dataKey={raktas}
              data={data}
              label={renderCustomizedLabel(spalvos)}
              innerRadius="60%"
              legendType="circle"
              startAngle={startAngle ? startAngle : 90}
              endAngle={endAngle ? endAngle : 450}
              overflow={"visible"}
            >
              {duomenys.map((d, index) => (
                <Cell key={`cell-${index}`} fill={spalvos ? spalvos[index] : PieColors[index]} />
              ))}
            </Pie>
            <Legend layout="horizontal" align="bottom" />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

export default SveikatSkritDiagrama;
