import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Karves from "../component/home/Karves";
import { useMenu } from "../context/MenuProvider";
import Bandos from "../component/home/Bandos";
import RibuInput from "../component/shared/RibuInput";
import MenesiuSelect from "../component/home/MenesiuSelect";
import { Ribos } from "../constants/ParametruRibos";
import { postItem } from "../Api/Api";
import ProdGrafikas from "../component/home/ProdGrafikas";
import {
  LaktacijuAmziai,
  LaktozesGrupes,
  RBGrupes,
  SomatiniuGrupes,
  UrejosGrupes,
} from "../constants/ParametruGrupes";
import GrupiuSelectList from "../component/shared/GrupiuSelectuList";
import { useAuth } from "../context/AuthProvider";
import VidurkiuLentele from "../component/home/VidurkiuLentele";
import { StyledPaper } from "../component/shared/StyledComponents";
import { useNavigate } from "react-router-dom";
import { kontrolesPeriodai } from "../constants/KontrolesPeriodai";

//For loopai veikia truputi greiciau nei filter/reduce kombinacija, tai palieku dabar sita
export const filtruotiGrupes = (grupes, visosGrupes, duomenys, parametras) => {
  let filtruotiDuomenys = [];
  const atrinktosGrupes = visosGrupes.filter((l) => grupes.indexOf(l.id) > -1);

  for (let i = 0; i < duomenys.length; i++) {
    let tinka = false;
    for (let j = 0; j < atrinktosGrupes.length; j++) {
      if (
        duomenys[i][`${parametras}`] >= atrinktosGrupes[j].min &&
        duomenys[i][`${parametras}`] <= atrinktosGrupes[j].max
      ) {
        tinka = true;
        break;
      }
    }

    if (tinka) {
      filtruotiDuomenys.push(duomenys[i]);
    }
  }

  return filtruotiDuomenys;
};

const Home = () => {
  const menu = useMenu();
  const auth = useAuth();
  const savininkas = menu.savininkas;
  const arSavininkas = auth.user.role === "Ūkis";

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [duomenys, setDuomenys] = useState([]);
  const [bandos, setBandos] = useState([]);
  const [karves, setKarves] = useState([]);
  const [menesiai, setMenesiai] = useState([kontrolesPeriodai[0]]);

  //Ribu parametrai
  const [primilzis, setPrimilzis] = useState(Ribos.get("Primilzis").ribos);
  const [bazinisPrimilzis, setBazinisPrimilzis] = useState(Ribos.get("Bazinis").ribos);
  const [riebalai, setRiebalai] = useState(Ribos.get("Riebalai").ribos);
  const [baltymai, setBaltymai] = useState(Ribos.get("Baltymai").ribos);
  const [laktoze, setLaktoze] = useState(Ribos.get("Laktoze").ribos);
  const [somatines, setSomatines] = useState(Ribos.get("Somatines").ribos);
  const [urea, setUrea] = useState(Ribos.get("Urea").ribos);
  const [laktTrukme, setLaktTrukme] = useState(Ribos.get("Trukme").ribos);

  //Grupiu busenos
  const [veisles, setVeisles] = useState([]);
  //Pagal nutylejima visos grupes bus pasirinktos
  const [laktacijos, setLaktacijos] = useState(LaktacijuAmziai.map((g) => g.id));
  const [slsGrupes, setSlsGrupes] = useState(SomatiniuGrupes.map((g) => g.id));
  const [rbGrupes, setRbGrupes] = useState(RBGrupes.map((g) => g.id));
  const [urejosGrupes, setUrejosGrupes] = useState(UrejosGrupes.map((g) => g.id));
  const [laktozesGrupes, setLaktozesGrupes] = useState(LaktozesGrupes.map((g) => g.id));

  const [klaida, setKlaida] = useState("");

  let veisliuList = [];
  let filtruota = duomenys;
  // console.log("unfiltered data is: ", filtruota);
  // for (var f = 0; f < filtruota.length; f++) {
  //   if (filtruota[f].karNr === "LT000008734501") {
  //     console.log("Yra");
  //     console.log(filtruota[f]);
  //     // console.log(f);
  //   }
  // }
  bandos.length > 0 && (filtruota = filtruota.filter((d) => bandos.indexOf(d.banda) > -1));
  veisliuList = [...new Set(duomenys.map((d) => d.veisle))];
  veisles.length > 0 &&
    veisles.length < veisliuList.length &&
    (filtruota = filtruota.filter((d) => veisles.indexOf(d.veisle) > -1));
  karves.length > 0 && (filtruota = filtruota.filter((d) => karves.indexOf(d.karNr) > -1));
  filtruota = filtruota.filter(
    (d) =>
      d.laktDiena >= Math.max(laktTrukme.min, Ribos.get("Trukme").ribos.min) &&
      d.laktDiena <= Math.min(laktTrukme.max, Ribos.get("Trukme").ribos.max) &&
      d.primilzis >= Math.max(primilzis.min, Ribos.get("Primilzis").ribos.min) &&
      d.primilzis <= Math.min(primilzis.max, Ribos.get("Primilzis").ribos.max) &&
      d.bazinis >= Math.max(bazinisPrimilzis.min, Ribos.get("Bazinis").ribos.min) &&
      d.bazinis <= Math.min(bazinisPrimilzis.max, Ribos.get("Bazinis").ribos.max) &&
      d.riebalai >= Math.max(riebalai.min, Ribos.get("Riebalai").ribos.min) &&
      d.riebalai <= Math.min(riebalai.max, Ribos.get("Riebalai").ribos.max) &&
      d.baltymai >= Math.max(baltymai.min, Ribos.get("Baltymai").ribos.min) &&
      d.laktoze <= Math.min(laktoze.max, Ribos.get("Laktoze").ribos.max) &&
      d.laktoze >= Math.max(laktoze.min, Ribos.get("Laktoze").ribos.min) &&
      d.baltymai <= Math.min(baltymai.max, Ribos.get("Baltymai").ribos.max) &&
      d.sls >= Math.max(somatines.min, Ribos.get("Somatines").ribos.min) &&
      d.sls <= Math.min(somatines.max, Ribos.get("Somatines").ribos.max) &&
      d.urea >= Math.max(urea.min, Ribos.get("Urea").ribos.min) &&
      d.urea <= Math.min(urea.max, Ribos.get("Urea").ribos.max)
  );

  //Filtruojam laktaciju grupes
  laktacijos.length > 0 &&
    laktacijos.length < LaktacijuAmziai.length &&
    (filtruota = filtruotiGrupes(laktacijos, LaktacijuAmziai, filtruota, "laktAmzius"));

  //Filtruojam sls grupes
  slsGrupes.length > 0 &&
    slsGrupes.length < SomatiniuGrupes.length &&
    (filtruota = filtruotiGrupes(slsGrupes, SomatiniuGrupes, filtruota, "sls"));

  //Filtruojam r/b grupes
  rbGrupes.length > 0 &&
    rbGrupes.length < RBGrupes.length &&
    (filtruota = filtruotiGrupes(rbGrupes, RBGrupes, filtruota, "rb"));

  //Filtruojam urejos grupes
  urejosGrupes.length > 0 &&
    urejosGrupes.length < UrejosGrupes.length &&
    (filtruota = filtruotiGrupes(urejosGrupes, UrejosGrupes, filtruota, "urea"));

  //Filtruojam laktozes grupes
  laktozesGrupes.length > 0 &&
    laktozesGrupes.length < LaktozesGrupes.length &&
    (filtruota = filtruotiGrupes(laktozesGrupes, LaktozesGrupes, filtruota, "laktoze"));

  useEffect(() => {
    if (savininkas !== "" || arSavininkas) {
      setIsLoading(true);
      let requestBody = arSavininkas ? {} : { gptKodas: savininkas };
      postItem("menesioProd", requestBody)
        .then((data) => {
          data.data && setDuomenys(data.data);
          setKlaida("");
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err.response.data);
          if (err.response.status === 404) {
            setKlaida(err.response.data.Error);
          }
          if (err.response.status === 401) {
            navigate("/login");
          }
          setIsLoading(false);
        });
    }

    setBandos([]);
    setKarves([]);
    setLaktacijos([]);
    setVeisles([]);

    // eslint-disable-next-line
  }, [menu.savininkas, savininkas, arSavininkas]);

  const handleChangeBanda = (checked, value) => {
    if (checked) {
      setBandos([...bandos, Number(value)]);
    } else {
      setBandos(bandos.filter((b) => b !== Number(value)));
    }
  };

  const handleChangeVisasBandas = (arr) => {
    setBandos(arr);
  };

  const handleChangeKarve = (checked, value) => {
    if (checked) {
      setKarves([...karves, value]);
    } else {
      setKarves(karves.filter((k) => k !== value));
    }
  };

  const handleChangeKarvesVisos = (arr) => {
    setKarves(arr);
  };

  if (klaida !== "") {
    return (
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography>{klaida}</Typography>
      </Box>
    );
  }

  return (
    <>
      <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" sx={{ marginBottom: "20px" }}>
          Produktyvumo rodiklių kitimas per laktaciją
        </Typography>
        <Grid container justifyContent="center" spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} lg={3}>
            <StyledPaper
              sx={{
                height: 325,
              }}
            >
              <Bandos
                isLoading={isLoading}
                savId={savininkas}
                bandos={bandos}
                changeBandos={handleChangeBanda}
                handleZymVisosBandos={handleChangeVisasBandas}
              />
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={3}>
            <StyledPaper
              sx={{
                height: 325,
              }}
            >
              <Karves
                isLoading={isLoading}
                savId={savininkas}
                bandos={bandos}
                karves={karves}
                changeKarves={handleChangeKarve}
                handleZymVisasKarves={handleChangeKarvesVisos}
              />
            </StyledPaper>
          </Grid>
          <Grid item container direction="column" spacing={2} xs={12} lg={6}>
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <StyledPaper>
                  <MenesiuSelect menesiai={menesiai} changeMenesiai={setMenesiai} />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Riebalai")}
                    reiksmes={riebalai}
                    changeReiksmes={setRiebalai}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Somatines")}
                    reiksmes={somatines}
                    changeReiksmes={setSomatines}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Primilzis")}
                    reiksmes={primilzis}
                    changeReiksmes={setPrimilzis}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Baltymai")}
                    reiksmes={baltymai}
                    changeReiksmes={setBaltymai}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput ribos={Ribos.get("Urea")} reiksmes={urea} changeReiksmes={setUrea} />
                </StyledPaper>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Bazinis")}
                    reiksmes={bazinisPrimilzis}
                    changeReiksmes={setBazinisPrimilzis}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Laktoze")}
                    reiksmes={laktoze}
                    changeReiksmes={setLaktoze}
                  />
                </StyledPaper>
              </Grid>
              <Grid item xs={4}>
                <StyledPaper>
                  <RibuInput
                    ribos={Ribos.get("Trukme")}
                    reiksmes={laktTrukme}
                    changeReiksmes={setLaktTrukme}
                  />
                </StyledPaper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          spacing={2}
          sx={{ mb: 4, backgroundColor: "white" }}
        >
          <Grid item xs={12} lg={8}>
            <StyledPaper
              row="true"
              sx={{
                width: "100%",
                paddingTop: "20px",
                paddingLeft: "10px",
                gap: "10px",
              }}
            >
              <Grid container item xs={12} spacing={2}>
                <Grid item xs={12} lg={10}>
                  <ProdGrafikas isLoading={isLoading} duomenys={filtruota} menesiai={menesiai} />
                </Grid>
                <Grid item xs={12} lg={2} display="flex">
                  <GrupiuSelectList
                    veisliuList={veisliuList}
                    veisles={veisles}
                    changeVeisles={setVeisles}
                    laktacijos={laktacijos}
                    changeLaktacijas={setLaktacijos}
                    slsGrupes={slsGrupes}
                    changeSlsGrupes={setSlsGrupes}
                    rbGrupes={rbGrupes}
                    changeRbGrupes={setRbGrupes}
                    urejosGrupes={urejosGrupes}
                    changeUrejosGrupes={setUrejosGrupes}
                    laktozesGrupes={laktozesGrupes}
                    changeLaktozesGrupes={setLaktozesGrupes}
                  />
                </Grid>
              </Grid>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} lg={4}>
            <VidurkiuLentele savininkas={savininkas} menesiai={menesiai} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Home;
